import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function GastosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_CONCEPTOS_GASTOS = process.env.REACT_APP_URL_CONCEPTOS_GASTOS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CONCEPTOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

  const calcula = (e) => {
    setSubtotal(e);
    setTotal(parseFloat(e) + parseFloat(iva));
  };

  const calculaIVA = (e) => {
    setIva(e);
    setTotal(parseFloat(subtotal) + parseFloat(e));
  };

  const saveGasto = (event) => {
    event.preventDefault();
    if(subtotal > 0 &&
      selectedBanco != "" ){
    setValidaBoton(false);
        axios.post(
          URL_GASTOS,
          {
            is_active:"Si",
            fecha,
            subtotal,
            iva,
            total,
            descripcion,
            areas: AREAS_GENERAL,
            conceptosGastos:selectedConcepto,
            bancos: selectedBanco,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then((data) => {
        axios
        .post(
          `${URL_MOVIMIENTOS_BANCOS}`,
          {
            fecha,
            importe: -total,
            tipo:"Egreso",
            bancos: selectedBanco,
            gastos:data.data._id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        )

        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Gasto",
            detalle: `Fecha: ${fecha} / Concepto: ${selectedConcepto} / Total: ${total} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes completar todo el formulario",
      });
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">Nuevo Gasto</h3>
            <Form onSubmit={saveGasto}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>

                <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                  </Row>
                  <Row>
                <Col md={4}>
                    <Label className="mr-sm-2">Concepto</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedConcepto}
                        onChange={(e) => setSelectedConcepto(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {conceptosGastos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>

               
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Descripcion
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      setSelectedBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Subtotal
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Subtotal"
                      value={subtotal}
                      required
                      onChange={(e) => calcula(e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      IVA
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="IVA"
                      value={iva}
                      required
                      onChange={(e) => calculaIVA(e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Total
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder={total}
                      disabled
                    />
                  </Col>
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/Gastos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default GastosCreate;
