import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesGastos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
        <Row >
          <Col md={4} align="center">
          {user.menu_gastos ?(
            <Button href="/ConceptosGastos" className="botonesMenu" color="success">
              <i class="far fa-list-alt fa-7x"></i>
              <br />
              <br />
              Conceptos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="far fa-list-alt fa-7x"></i>
          <br />
          <br />
          Conceptos
        </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/Gastos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Gastos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Gastos
            </Button> }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesGastos;
