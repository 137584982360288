import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input,   Spinner,
  Modal,
  ModalHeader,
  ModalBody, } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import encode from "nodejs-base64-encode";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCompraCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_TELAS_OC = process.env.REACT_APP_URL_TELAS_OC;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  // const [mailTo, setMailTo] = useState("danysalame18@gmail.com");

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [colores, setColores] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState(0);
  const [nombreProveedor, setNombreProveedor] = useState("");
  const [emailProveedor, setEmailProveedor] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);

  const [tipo, setTipo] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      telas: "",
      habilitacion: "",
      unidad: "",
      colores: "",
      arrayColoresTela: [],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      colorNombre: "",
      articuloNombre: "",
    },
  ]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabilitacion = res.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalRollos = inputFields.length;

    try {
      await axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha,
            proveedores: selectedProveedor,
            plazo,
            fecha_compromiso,
            total_general,
            observaciones,
            total_kilos,
            total_metros,
            total_piezas,
            tipo,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          if (tipo == "Tela") {
            inputFields.map((a) => {
              axios
                .post(
                  URL_TELAS_OC,
                  {
                    ordenesCompra: data.data._id,
                    telas: a.telas,
                    colores: a.colores,
                    cantidad: parseFloat(a.cantidad),
                    recibido: 0,
                    pendiente_recibir: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    let mts = inputFields.filter((e) => e.unidad == "Metros");
                    let totalMts = mts.map((c) => parseFloat(c.cantidad));
                    let TM = totalMts.reduce((t, total, index) => t + total, 0);
                    let KG = inputFields.filter((e) => e.unidad == "Kilos");
                    let totalKG = KG.map((c) => parseFloat(c.cantidad));
                    let TK = totalKG.reduce((t, total, index) => t + total, 0);
                    let PZ = inputFields.filter((e) => e.unidad == "Piezas");
                    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
                    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
                    let importes = inputFields.map((c) => parseFloat(c.total));
                    let TG = importes.reduce((t, total, index) => t + total, 0);

                    const dataIF = inputFields.map((a) => {
                      return [
                        a.articuloNombre,
                        a.unidad,
                        a.colorNombre,
                        new Intl.NumberFormat("en-US").format(a.cantidad),
                        "$" + new Intl.NumberFormat("en-US").format(a.precio),
                        a.descuento + " %",
                        "$" + new Intl.NumberFormat("en-US").format(a.total),
                      ];
                    });
                    const dataPDF = Object.values(dataIF);
                    const dataPDFLimpia = dataPDF.filter(function (el) {
                      return el != null;
                    });
                    const doc = new jsPDF("landscape");
                    var img = new Image();
                    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                    doc.addImage(img, "png", 230, 30, 45, 15);
                    var img2 = new Image();
                    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                    doc.addImage(img2, "png", 120, 15, 60, 25);
                    doc.text(
                      `Orden de Compra # ${data.data.idOrdenCompra}`,
                      20,
                      20
                    );
                    doc.setFontSize(12);
                    doc.text(`Fecha ${fecha}`, 20, 25);
                    doc.text(`Proveedor ${nombreProveedor}`, 20, 30);
                    doc.text(`Fecha Compromiso ${fecha_compromiso}`, 20, 35);
                    doc.text(
                      `Metros ${new Intl.NumberFormat("en-US").format(
                        TM
                      )} / Kilos ${new Intl.NumberFormat("en-US").format(
                        TK
                      )} / Piezas ${new Intl.NumberFormat("en-US").format(TP)}`,
                      20,
                      40
                    );
                    doc.autoTable({
                      head: [
                        [
                          "Articulo",
                          "Unidad",
                          "Color",
                          "Cantidad",
                          "Precio",
                          "Descuento",
                          "Total",
                        ],
                      ],
                      body: dataPDFLimpia,
                      startY: 45,
                      foot: [
                        [
                          "",
                          "",
                          "",
                          "",
                          "",
                          "Total",
                          "$" + new Intl.NumberFormat("en-US").format(TG),
                        ],
                      ],
                      showFoot: "lastPage",
                    });

                    var att = doc.output("arraybuffer");
                    var base64File = encode.encode(att, "base64");

                    axios
                      .post(
                        URL_SEND_MAIL_ATT,
                        {
                          subject: "Orden Compra",
                          email: emailProveedor,
                          fileName: "OrdenCompra.pdf",
                          att: base64File,
                          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
                        <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
                        <tbody>
                        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
                        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras una nueva Orden de Compra.</tr>
                        </tbody>
                        <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
                        <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
                        </table>`,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        axios.post(
                          URL_LOGS,
                          {
                            tipo: "Crear Orden de Compra",
                            detalle: `${selectedProveedor} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        );
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          // footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });
          } else {
            inputFields.map((a) => {
              axios
                .post(
                  URL_TELAS_OC,
                  {
                    ordenesCompra: data.data._id,
                    habilitacion: a.habilitacion,
                    cantidad: parseFloat(a.cantidad),
                    recibido: 0,
                    pendiente_recibir: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    let mts = inputFields.filter((e) => e.unidad == "Metros");
                    let totalMts = mts.map((c) => parseFloat(c.cantidad));
                    let TM = totalMts.reduce((t, total, index) => t + total, 0);
                    let KG = inputFields.filter((e) => e.unidad == "Kilos");
                    let totalKG = KG.map((c) => parseFloat(c.cantidad));
                    let TK = totalKG.reduce((t, total, index) => t + total, 0);
                    let PZ = inputFields.filter((e) => e.unidad == "Piezas");
                    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
                    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
                    let importes = inputFields.map((c) => parseFloat(c.total));
                    let TG = importes.reduce((t, total, index) => t + total, 0);

                    const dataIF = inputFields.map((a) => {
                      return [
                        a.articuloNombre,
                        a.unidad,
                        a.colorNombre,
                        new Intl.NumberFormat("en-US").format(a.cantidad),
                        "$" + new Intl.NumberFormat("en-US").format(a.precio),
                        a.descuento + " %",
                        "$" + new Intl.NumberFormat("en-US").format(a.total),
                      ];
                    });
                    const dataPDF = Object.values(dataIF);
                    const dataPDFLimpia = dataPDF.filter(function (el) {
                      return el != null;
                    });
                    const doc = new jsPDF("landscape");
                    var img = new Image();
                    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                    doc.addImage(img, "png", 230, 30, 45, 15);
                    var img2 = new Image();
                    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                    doc.addImage(img2, "png", 120, 15, 60, 25);
                    doc.text(
                      `Orden de Compra # ${data.data.idOrdenCompra}`,
                      20,
                      20
                    );
                    doc.setFontSize(12);
                    doc.text(`Fecha ${fecha}`, 20, 25);
                    doc.text(`Proveedor ${nombreProveedor}`, 20, 30);
                    doc.text(`Fecha Compromiso ${fecha_compromiso}`, 20, 35);
                    doc.text(
                      `Metros ${new Intl.NumberFormat("en-US").format(
                        TM
                      )} / Kilos ${new Intl.NumberFormat("en-US").format(
                        TK
                      )} / Piezas ${new Intl.NumberFormat("en-US").format(TP)}`,
                      20,
                      40
                    );
                    doc.autoTable({
                      head: [
                        [
                          "Articulo",
                          "Unidad",
                          "Color",
                          "Cantidad",
                          "Precio",
                          "Descuento",
                          "Total",
                        ],
                      ],
                      body: dataPDFLimpia,
                      startY: 45,
                      foot: [
                        [
                          "",
                          "",
                          "",
                          "",
                          "",
                          "Total",
                          "$" + new Intl.NumberFormat("en-US").format(TG),
                        ],
                      ],
                      showFoot: "lastPage",
                    });

                    var att = doc.output("arraybuffer");
                    var base64File = encode.encode(att, "base64");

                    axios
                      .post(
                        URL_SEND_MAIL_ATT,
                        {
                          subject: "Orden Compra",
                          email: emailProveedor,
                          fileName: "OrdenCompra.pdf",
                          att: base64File,
                          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
                        <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
                        <tbody>
                        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
                        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras una nueva Orden de Compra.</tr>
                        </tbody>
                        <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
                        <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
                        </table>`,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        axios.post(
                          URL_LOGS,
                          {
                            tipo: "Crear Orden de Compra",
                            detalle: `${selectedProveedor} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        );
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          // footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        telas: ultimo.telas,
        habilitacion: ultimo.habilitacion,
        unidad: ultimo.unidad,
        colores: ultimo.colores,
        arrayColoresTela: ultimo.arrayColoresTela,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        colorNombre: ultimo.colorNombre,
        articuloNombre: ultimo.articuloNombre,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = values.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaProveedor(event) {
    proveedores.map((a) => {
      if (a._id == event.target.value) {
        setSelectedProveedor(event.target.value);
        setNombreProveedor(a.nombre_comercial);
        setEmailProveedor(a.email);
        // setPlazo(a.plazo);
      }
    });
  }

  function BuscaTela(id, event) {
    telas.map((a) => {
      if (a._id == event.target.value) {
        let idTela = a._id;
        let unidadTela = a.unidad;
        let coloresTela = a.colores;
        let costo = a.costo;
        let nombre = a.codigo + " " + a.nombre;
        handleChangeInputTela(
          id,
          idTela,
          unidadTela,
          coloresTela,
          costo,
          nombre
        );
      }
    });
  }

  const handleChangeInputTela = (
    id,
    idTela,
    unidadTela,
    coloresTela,
    costo,
    nombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.telas = idTela;
        i.unidad = unidadTela;
        i.arrayColoresTela = coloresTela;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.precio = costo;
        i.articuloNombre = nombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaHabilitacion(id, event) {
    habilitacion.map((a) => {
      if (a._id == event.target.value) {
        let idHabilitacion = a._id;
        let unidadHabilitacion = a.unidad;
        let costo = a.costo;
        let nombre = a.nombre;
        handleChangeInputHabilitacion(
          id,
          idHabilitacion,
          unidadHabilitacion,
          costo,
          nombre
        );
      }
    });
  }

  const handleChangeInputHabilitacion = (
    id,
    idHabilitacion,
    unidadHabilitacion,
    costo,
    nombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.habilitacion = idHabilitacion;
        i.unidad = unidadHabilitacion;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.precio = costo;
        i.articuloNombre = nombre;
        i.colorNombre = "NA";
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaColor(id, event) {
    colores.map((a) => {
      if (a._id == event.target.value) {
        let idColor = a._id;
        let nombre = a.name;
        handleChangeInputColor(id, idColor, nombre);
      }
    });
  }

  const handleChangeInputColor = (id, idColor, nombre) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.colores = idColor;
        i.colorNombre = nombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = inputFields.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.oc_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Orden de Compra</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
              <Col md={2}>
                <Label>Tipo</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={tipo}
                  required
                  onChange={(e) => {
                    setTipo(e.target.value);
                    setInputFields([
                      {
                        id: uuidv4(),
                        telas: "",
                        habilitacion: "",
                        unidad: "",
                        colores: "",
                        arrayColoresTela: [],
                        cantidad: 0,
                        precio: 0,
                        descuento: 0,
                        total: 0,
                        colorNombre: "",
                        articuloNombre: "",
                      },
                    ]);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Tela">Tela</option>
                  <option value="Habilitacion">Habilitacion</option>
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(event) => {
                    // setSelectedProveedor(e.target.value);
                    BuscaProveedor(event);
                  }}
                >
                  <option value="0">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Proveedor" && a.is_active == "Si") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              {/* <Col md={2}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={2}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <h4 id="logoutBoton">
                  TOTALES {total_kilos} kg. / {total_metros} mts. /{" "}
                  {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Telas */}

            <Row>
              {tipo == "Tela" ? (
                <Col md={2}>
                  <Label className="mr-sm-2">Telas</Label>
                </Col>
              ) : (
                <Col md={2}>
                  <Label className="mr-sm-2">Habilitacion</Label>
                </Col>
              )}

              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>

              {tipo == "Tela" ? (
                <Col md={2}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
              ) : undefined}

              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Descuento</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  {tipo == "Tela" ? (
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="telas"
                        type="select"
                        value={inputField.telas}
                        required
                        onChange={(event) => {
                          BuscaTela(inputField.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                          .map((a) => {
                            if(a.is_active == "Si"){
                            return (
                              <option value={a._id}>
                                {a.nombre}
                              </option>
                            )}
                          })}
                      </Input>
                    </Col>
                  ) : (
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="habilitacion"
                        type="select"
                        value={inputField.habilitacion}
                        required
                        onChange={(event) => {
                          BuscaHabilitacion(inputField.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {habilitacion.map((a) => {
                          if(a.is_active == "Si"){
                          return <option value={a._id}>{a.nombre}</option>;}
                        })}
                      </Input>
                    </Col>
                  )}
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>
                  {tipo == "Tela" ? (
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) => BuscaColor(inputField.id, event)}
                      >
                        <option value="">Selecciona un Color</option>
                        {inputField.arrayColoresTela
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>
                  ) : undefined}

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={inputField.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_kilos} kg. / {total_metros} mts. /{" "}
                  {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Telas */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoOrdenesCompra"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCompraCreate;
