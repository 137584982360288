import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCXP() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_adminCxP ?(
      <div className="container">
        <br />
        <br />
        {/* <br />
          <br />
          <h2>Proveedores</h2>
          <Row>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoComprasCXP" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Compras
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Compras
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoAbonosProveedores" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos <br/> Proveedores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos <br/> Proveedores
        </Button> }
          </Col>

        
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/EstadoCuentaCXP" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta <br/> Proveedores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta <br/> Proveedores
        </Button> }
          </Col>
          </Row>
          <br/>
          <Row>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoDevolucionesProveedoresadmin" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>
          </Row>
<br/>
<h2>Maquileros</h2> */}
          <Row>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoNotasCXP" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Notas <br/> Maquileros
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Notas <br/> Maquileros
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoAbonosMaquileros" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos <br/> Maquileros 
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos <br/> Maquileros
        </Button> }
          </Col>

        
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/EstadoCuentaMaq" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta <br/> Maquileros 
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta <br/> Maquileros 
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/SalidaHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tags fa-7x"></i>
              <br />
              <br />
              Salidas Habilitacion
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tags fa-7x"></i>
          <br />
          <br />
          Salidas Habilitacion
        </Button> }
          </Col>
          
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoAnticiposProveedores" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Anticipos <br/> Maquileros 
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Anticipos <br/> Maquileros
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/ListadoFaltantesProveedores" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Aplicar <br/> Faltantes 
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Aplicar <br/> Faltantes
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_adminCxP ?(
            <Button href="/PendientesCxP" className="botonesMenu" color="success">
              <i class="fas fa-sliders-h fa-7x"></i>
              <br />
              <br />
              Pendientes <br/> CxP
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sliders-h fa-7x"></i>
          <br />
          <br />
          Pendientes <br/>CxP
        </Button> }
          </Col>
          </Row>

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCXP;
