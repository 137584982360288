import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PADRE = process.env.REACT_APP_URL_ARTICULOS_PADRE;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_TEMPORADAS = process.env.REACT_APP_URL_TEMPORADAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CATALOGO_PROCESOS = process.env.REACT_APP_URL_CATALOGO_PROCESOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [is_active, setis_active] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [articulosPadre, setArticulosPadre] = useState([]);
  const [selectedArticulosPadre, setSelectedArticulosPadre] = useState("");
  const [selectedArticulosPadreEdit, setSelectedArticulosPadreEdit] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [catalogoProcesos, setCatalogoProcesos] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);
  const [explosionExistentes, setExplosionExistentes] = useState([]);
  const [codigoExplosion, setCodigoExplosion] = useState("");
  const [idExplosion, setIdExplosion] = useState("");
  const [nombreExplosion, setNombreExplosion] = useState("");

  const [idCatalogoProcesosArticulos, setIdCatalogoProcesosArticulos] = useState("");
  const [modalCatalogoProcesos, setModalCatalogoProcesos] = useState(false);
  const toggleCatalogoProcesos = () => setModalCatalogoProcesos(!modalCatalogoProcesos);
  const [arrayCatalogoProcesos, setArrayCatalogoProcesos] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      cantidad:0
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      idHab: "",
      cantidad:0
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              nombre: a.nombre,
              descripcion: a.descripcion,
              observaciones: a.observaciones,
              articulosPadre: a.articulosPadre[0].codigo + " " + a.articulosPadre[0].nombre,
              idArticulosPadre: a.articulosPadre[0]._id,
              catalogoProcesos: a.catalogoProcesos,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    
      axios
      .get(URL_ARTICULOS_PADRE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPadre = res.data;
        setArticulosPadre(allArticulosPadre);
      })
      .catch((err) => {
        console.log(err);
      });
    
      axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabilitacion = res.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CATALOGO_PROCESOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCatalogoProcesos = response.data;
        setCatalogoProcesos(allCatalogoProcesos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedArticulosPadre == 0 || selectedArticulosPadre == a.idArticulosPadre) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.articulosPadre,
          a.nombre,
          a.descripcion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Modelos", 20, 40);
    doc.autoTable({
      head: [
        [
          "Articulo Padre",
          "Nombre",
          "Descripcion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save("Modelos.pdf");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedArticulosPadre == 0 || selectedArticulosPadre == a.idArticulosPadre) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return {
          ArticuloPadre: a.articulosPadre,
          Nombre: a.nombre,
          Descripcion: a.descripcion,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Modelos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Modelos",
        sheetFilter: [
          "ArticuloPadre",
          "Nombre",
          "Descripcion",
        ],
        sheetHeader: [
          "ArticuloPadre",
          "Nombre",
          "Descripcion",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedArticulosPadre == 0 || selectedArticulosPadre == a.idArticulosPadre) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.articulosPadre,
          a.nombre,
          a.descripcion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Modelos", 20, 40);
    doc.autoTable({
      head: [
        [
          "Articulo Padre",
          "Nombre",
          "Descripcion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Modelos",
          email: mailTo,
          fileName: "ListadoModelos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Modelos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idArticulosPadre,
    nombre,
    descripcion,
    observaciones,
    idEdit,
    // idMuestrista,
    // idPatronista,
    // idPloter
  ) {
    setNombre(nombre);
    setDescripcion(descripcion);;
    setObservaciones(observaciones);
    setSelectedArticulosPadreEdit(idArticulosPadre);
    setIdEditArticulo(idEdit);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {
        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            nombre,
            descripcion,
            observaciones,
            articulosPadre: selectedArticulosPadreEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${nombre}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    } else{
        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            nombre,
            descripcion,
            observaciones,
            articulosPadre: selectedArticulosPadreEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idEditArticulo);
  
          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Articulo",
                  detalle: `${nombre}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
  
              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Articulo Padre", field: "articulosPadre", sortable: true },
    { name: "Prenda", field: "nombre", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment._id.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulosPadre.toLowerCase().includes(search.toLowerCase())
      );
    }
    
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedNombre)
      );
    }
    if (selectedArticulosPadre) {
      computedComments = computedComments.filter((e) =>
        e.idArticulosPadre.includes(selectedArticulosPadre)
      );
    }
    if (is_active) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(is_active)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" 
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" 
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedNombre,
    selectedArticulosPadre,
    is_active,
  ]);

  function explosion(id, nombre){
    setIdExplosion(id)
    setNombreExplosion(nombre)

    axios
    .get(`${URL_EXPLOSION}Articulo/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allExplosionExistentes = res.data;
      setExplosionExistentes(allExplosionExistentes);
    })
    .catch((err) => {
      console.log(err);
    });

    toggleExplosion()

  }

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
  };

  const handleAddFieldsTelas = () => {
    setInputFieldsTela([
      ...inputFieldsTela,
      {
        id: uuidv4(),
        idTela: "",
        cantidad:0
      },
    ]);
  };

  const handleAddFieldsHab = () => {
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        idHab: "",
        cantidad:0
      },
    ]);
  };

  const handleChangeInputTela = (id, event) => {
    const newInputFieldsTela = inputFieldsTela.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTela(newInputFieldsTela);
  };

  const handleChangeInputHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
  };

  const handleChangeInputExistente = (id, event) => {
    const newExplosionExistentes = explosionExistentes.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setExplosionExistentes(newExplosionExistentes);
  };

function saveExplosion(){
  setValidaBoton(false);
  toggleProgreso();

let totalExplosion = inputFieldsHab.length + inputFieldsTela.length + explosionExistentes.length

  inputFieldsTela.map((a)=>{
    if(a.idTela!="" & a.cantidad > 0){
    axios.post(
      URL_EXPLOSION,
      {
        articulos: idExplosion,
        telas: a.idTela,
        cantidad: a.cantidad,
        tipo: "Tela"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Explosion",
            detalle: `${idExplosion} ${a.idTela}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  }else{
    totalExplosion = totalExplosion -1
  }
  })

  inputFieldsHab.map((a)=>{
    if(a.idHab!="" & a.cantidad > 0){
    axios.post(
      URL_EXPLOSION,
      {
        articulos: idExplosion,
        habilitacion: a.idHab,
        cantidad: a.cantidad,
        tipo: "Habilitacion"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Explosion",
            detalle: `${idExplosion} ${a.idHab}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  }else{
    totalExplosion = totalExplosion -1
  }
  })

  explosionExistentes.map((a)=>{
    axios.patch(
      `${URL_EXPLOSION}/${a._id}`,
      {
        cantidad: a.cantidad,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Explosion",
            detalle: `${idExplosion}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  })

}

function catalogoProcesosInfo(idCatalogoProcesos, nombre, catalogoProcesosInfo) {
  let ids = catalogoProcesosInfo.map(function (e) {
    return e._id;
  });
  let catalogoProcesosDany = Object.values(ids);

  setIdCatalogoProcesosArticulos(idCatalogoProcesos);
  setNombre(nombre);
  setArrayCatalogoProcesos(catalogoProcesosDany);
  toggleCatalogoProcesos();
}

function catalogoProcesosSeleccionados(checked, idCatalogoProceso) {
  if (checked) {
    arrayCatalogoProcesos.push(idCatalogoProceso);
    console.log(arrayCatalogoProcesos);
  } else {
    const index = arrayCatalogoProcesos.indexOf(idCatalogoProceso);
    if (index > -1) {
      arrayCatalogoProcesos.splice(index, 1);
      console.log(arrayCatalogoProcesos);
    }
  }
}

function guardarCatalogoProcesos() {
  const URL_ARTICULOS_CATALOGO_PROCESOS = `${process.env.REACT_APP_URL_ARTICULOS}/${idCatalogoProcesosArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_CATALOGO_PROCESOS,
      {
        catalogoProcesos: arrayCatalogoProcesos,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "CatalogoProcesos Articulo",
          detalle: `${nombre}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function jalaFoto(
  idEdit
) {
  setPhoto("")
  const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
  axios
    .get(URL_GET_MEDIA, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((data) => {
      if (data.data.version) {
        setPhoto(data.data.version);
      }
    })
    .catch((err) => console.log(err));


  setIdEditArticulo(idEdit);
  toggleFoto();
}
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreate"
                >
                  Nuevo Modelo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Modelo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>
                Enviar Modelos
              </h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Modelos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulosPadre}
                  onChange={(e) => {
                    setSelectedArticulosPadre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosPadre
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo} {a.nombre}</option>;
                    })}
                </Input>
              </td>
              
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.is_active}</td>
                      <td>{a.articulosPadre}</td>
                      <td>{a.nombre}</td>
                      
                      <td>
                        {user.articulos_create ? (
                          <div>
                            <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                explosion(
                                  a._id,
                                  a.nombre
                                )
                              }
                            >
                              <i class="fas fa-tag"></i>
                            </Button>
                            <Button
                              color="danger"
                              id="CatalogoProcesos"
                              size="sm"
                              onClick={(e) =>
                                catalogoProcesosInfo(
                                  a._id,
                                  a.nombre,
                                  a.catalogoProcesos
                                )
                              }
                            >
                            <i class="fas fa-tshirt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.idArticulosPadre,
                                  a.nombre,
                                  a.descripcion,
                                  a.observaciones,
                                  a._id,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.is_active}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Modelo / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
        <Col md={3}>
                <Label className="mr-sm-2">Articulo Padre</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArticulosPadreEdit}
                  required
                  onChange={(e) => {
                    setSelectedArticulosPadreEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {articulosPadre
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo} {a.nombre}</option>;
                    })}
                </Input>
              </Col>
              
              <Col md={3}>
                <Label>Prenda</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>              
              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              
              </Row>
              <Row>
              <Col md={3}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
              </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>
            Explosion Modelo {codigoExplosion} / {nombreExplosion}
          </h4>
        </ModalHeader>
        <ModalBody>

          <Row>
            <Col md={6}>
            <Label>Tela</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a)=>(
            a.tipo=="Tela"?(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="telas"
                        type="select"
                        value={a.telas[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>):undefined
          ))}
             {inputFieldsTela.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idTela"
                        type="select"
                        value={a.idTela}
                        required
                        onChange={(event)=>{handleChangeInputTela(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputTela(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsTela.length === 1}
                        onClick={() => handleRemoveFieldsTelas(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsTelas}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}
    <br/>
        <Row>
            <Col md={6}>
            <Label>Habilitacion</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a)=>(
            a.tipo=="Habilitacion"?(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="habilitacion"
                        type="select"
                        value={a.habilitacion[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                 {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>):undefined
          ))}
          {inputFieldsHab.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idHab"
                        type="select"
                        value={a.idHab}
                        required
                        onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHab}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}
      </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveExplosion}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalCatalogoProcesos} toggle={toggleCatalogoProcesos}>
        <ModalHeader toggle={toggleCatalogoProcesos}>
          <h4>
            Procesos del Articulo / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          {catalogoProcesos
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayCatalogoProcesos.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          catalogoProcesosSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarCatalogoProcesos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/frateloArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoArticulos;
