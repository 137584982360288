import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesAdmin() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/Bancos" className="botonesMenu" color="success">
              <i class="fas fa-university fa-7x"></i>
              <br />
              <br />
              Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-university fa-7x"></i>
          <br />
          <br />
          Bancos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoVentas" className="botonesMenu" color="success">
              <i class="fas fa-money-bill-wave fa-7x"></i>
              <br />
              <br />
              Ventas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-bill-wave fa-7x"></i>
          <br />
          <br />
          Ventas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin  ?(
            <Button href="/EstadoCuenta" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>
          
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_admin?(
            <Button href="/ListadoAbonos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoNotasCargo" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Notas de Cargo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Notas de Cargo
        </Button> }
          </Col>
           <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoDevolucionesAdmin" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>

          </Row>
<br />
          <Row>
          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoConceptosBonificaciones" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Conceptos <br /> Bonificaciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-bars fa-7x"></i>
          <br />
          <br />
          Conceptos <br /> Bonificaciones
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_admin ?(
            <Button href="/ListadoBonificaciones" className="botonesMenu" color="success">
              <i class="fas fa-search-dollar fa-7x"></i>
              <br />
              <br />
              Bonificaciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search-dollar fa-7x"></i>
          <br />
          <br />
          Bonificaciones
        </Button> }
          </Col>
          </Row>


      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesAdmin;
