import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Spinner
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import Baja from "../../Baja";
import { v4 as uuidv4 } from "uuid";


function SalidaHabilitacion() {
  const { user } = useContext(AuthContext);
  const URL_HABILITACION_CORTES = process.env.REACT_APP_URL_HABILITACION_CORTES;
  const URL_DETALLE_HABILITACION_CORTES = process.env.REACT_APP_URL_DETALLE_HABILITACION_CORTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_ARTICULOS_PROCESOS_SALIDA = process.env.REACT_APP_URL_ARTICULOS_PROCESOS_SALIDA;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let comp8 = new Date();
  comp8.setDate(comp8.getDate() + 8);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  let compromiso8 = moment(comp8).format("YYYY-MM-DD");

  const [procesos, setProcesos] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [maquileros, setMaquileros] = useState([]);
  const [selectedMaquilero, setSelectedMaquilero] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState();
  const [conceptosGastos, setConceptosGastos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");

  const [modalAplicaDevolucion, setModalAplicaDevolucion] = useState(false);
  const toggleAplicaDevolucion = () => setModalAplicaDevolucion(!modalAplicaDevolucion);
  const [maquilero, setMaquilero] = useState();
  const [maquileroName, setMaquileroName] = useState();
  const [totalAplicar, setTotalAplicar] = useState(0);
  const [inputFields, setInputFields] = useState([]);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [validaBoton, setValidaBoton] = useState(true);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());


  const [articulosPSEdit, setArticulosPSEdit] = useState([]);
  const [modalEditPS, setModalEditPS] = useState(false);
  const toggleEditPS = () => setModalEditPS(!modalEditPS);
  const [totalHabilitacion, setTotalHabilitacion] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_HABILITACION_CORTES}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allProcesos = response.data;
        let arrayTabla = allProcesos
          .sort((a, b) => (a.fechaCompromiso > b.fechaCompromiso ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              aplicada: a.aplicada,
              fecha: a.fecha,
              corte: a.cortes[0].idCorte,
              proceso: a.procesosSalida[0].catalogoProcesos[0].name,
              idProcesos: a.procesosSalida[0]._id,
              total: a.total,
              saldo: a.saldo,
              maquilero: a.proveedores[0].nombre_comercial,
              maqDir: a.proveedores[0].calle + " " + a.proveedores[0].numero_ext  + " " + a.proveedores[0].numero_int,
              maqDir2: a.proveedores[0].estado + " , " + a.proveedores[0].ciudad  + " , " + a.proveedores[0].numero_int,
              idMaquilero: a.proveedores[0]._id,
              numero: a.idHabilitacionCortes,
              numeroOP: a.cortes[0].ordenesCorte[0].numeroOP,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setProcesos(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}Maquileros`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaquileros = response.data;
        setMaquileros(allMaquileros);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    totalTabla = 0;
    const data = procesos.map((c) => {
      if (
        // (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        // (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        totalTabla = totalTabla + c.total;
        return [
          c.fecha,
          c.maquilero,
          c.proceso,
          c.corte,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total),
          c.aplicada
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Salidas Habilitacion", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Corte",
          "Total",
          "Aplicada",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("ListadoSalidasHabilitacion.pdf");
  }

  function excel() {
    const dataExcel = procesos.map((c) => {
      if (
        // (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        // (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        return {
          Fecha: c.fecha,
          Maquilero: c.maquilero,
          Corte: c.corte,
          Proceso: c.proceso,
          Total: c.total,
          Aplicada: c.aplicada,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `ListadoSalidasHabilitacion`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `ListadoSalidasHabilitacion`,
        sheetFilter: [
          "Fecha",
          "Maquilero",
          "Corte",
          "Proceso",
          "Total",
          "Aplicada",
        ],
        sheetHeader: [
          "Fecha",
          "Maquilero",
          "Corte",
          "Proceso",
          "Total",
          "Aplicada",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    totalTabla = 0;
    const data = procesos.map((c) => {
      if (
        // (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        // (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedMaquilero == 0 || selectedMaquilero == c.idMaquilero)
      ) {
        totalTabla = totalTabla + c.total;
        return [
          c.fecha,
          c.maquilero,
          c.proceso,
          c.corte,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total),
          c.aplicada
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Salidas Habilitacion", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Maquilero",
          "Proceso",
          "Corte",
          "Total",
          "Aplicada",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Salida Habilitacion",
          email: mailTo,
          fileName: "SlaidasHabilitacion.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Salidas de Habilitacion.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(   
    fecha,
    maquilero,
    maqDir,
    maqDir2,
    proceso,
    corte,
    total,
    numero,
    idPDFOC ) {
       
       await axios
        .get(`${URL_DETALLE_HABILITACION_CORTES}/habilitacionCorte/${idPDFOC}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allHabilitaciones = response.data
          let arrayTabla = allHabilitaciones
          .map((a) => {
            return {
              id:a._id,
              habilitacion: a.habilitacion[0].nombre,
              cantidad: a.cantidad + " " + a.habilitacion[0].unidad,
              precio: a.precio,
              total: a.precio * a.cantidad,
            };
          }).filter(function (el) {
            return el != null;
          })
          var dataFinal = Object.values(arrayTabla);
  
          const data = dataFinal.map((a) => {
              return [
                a.habilitacion,
                a.cantidad,
                "$" + new Intl.NumberFormat("en-US").format(a.precio),
                "$" + new Intl.NumberFormat("en-US").format(a.total),
               ]
          });
          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });
          const doc = new jsPDF();
            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 150, 10, 35, 10);
            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 90, 15, 30, 30);
            doc.setFontSize(12);
            doc.text(`Maquilero ${maquilero}`, 20, 30);
            doc.text(`${maqDir}`, 20, 40);
            doc.text(`${maqDir2}`, 20, 45);
            doc.text(`Comercializadora Guibor SA de CV`, 125, 30);
            doc.text(`Ferrocarril de Acambaro #4 Bodega 6`, 125, 40);
            doc.text(`Alce Blanco, Naucalpan de Juárez`, 125, 45);
            doc.text(`CP: 53370`, 125, 50);
            doc.text(`Fecha: ${fecha}`, 20, 20);
          doc.autoTable({
            head: [["Articulo","Cantidad","Precio", "Total"]],
            body: dataPDFLimpia,
            startY: 55,
            foot: [
              [
                "",
                "",
                "Total",
                "$" + new Intl.NumberFormat("en-US").format(total),
              ],
            ],
            showFoot: "lastPage",
          });
          doc.text(`___________________`, 35, doc.autoTable.previous.finalY + 20);
          doc.text(`Recibio`, 50, doc.autoTable.previous.finalY + 25);
          doc.text(`___________________`, 85, doc.autoTable.previous.finalY + 20);
          doc.text(`Entrego`, 100, doc.autoTable.previous.finalY + 25);
          doc.text(`___________________`, 135, doc.autoTable.previous.finalY + 20);
          doc.text(`Reviso`, 150, doc.autoTable.previous.finalY + 25);
          doc.save(`SalidaHabilitacion-${numero}.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
  
       
    }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "OP", field: "numeroOP", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Maquilero", field: "maquilero", sortable: true },
    { name: "Proceso", field: "proceso", sortable: true },
    { name: "Corte", field: "corte", sortable: true },
    { name: "Total", field: "total", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Editar", field: "editar", sortable: true },
    { name: "Aplicar", field: "aplicar", sortable: false },
    { name: "PDF", field: "PDF", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.maquilero.toLowerCase().includes(search.toLowerCase()) ||
          comment.proceso.toLowerCase().includes(search.toLowerCase()) ||
          comment.aplicada.toLowerCase().includes(search.toLowerCase()) ||
          comment.corte.toString().includes(search)  ||
          comment.numero.toString().includes(search)  ||
          comment.numeroOP.toString().includes(search)  ||
          comment.saldo.toString().includes(search)  ||
          comment.total.toString().includes(search) 
      );
    }

    if (selectedMaquilero) {
      computedComments = computedComments.filter((e) =>
        e.idMaquilero.includes(selectedMaquilero)
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "corte" &&
      sorting.field != "numero" &&
      sorting.field != "numeroOP" &&
      sorting.field != "saldo" &&
      sorting.field != "total"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "corte" ||
      sorting.field == "numero" ||
      sorting.field == "numeroOP" ||
      sorting.field == "saldo" ||
        sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "corte" ||
      sorting.field == "numero" ||
      sorting.field == "numeroOP" ||
      sorting.field == "saldo" ||
      sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedMaquilero,
  ]);

  function aplicarAnticipo(
    idDev,
    idMaquilero,
    maquilero,
    total,
    saldo
  ) {
    setInputFields([])
    setIdEdit(idDev);
    setMaquilero(idMaquilero);
    setMaquileroName(maquilero);
    setTotalAplicar(0);
    setTotalGeneralEdit(saldo);

    axios
      .get(`${URL_NOTAS_CXP}Proveedor/${idMaquilero}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              nota: a.procesosEntrada[0].idProcesosEntrada,
              total_costo: a.total_costo,
              saldo: a.saldo,
              importe: 0,
              proveedores: a.proveedores[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleAplicaDevolucion();
  }

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (event.target.value <= i.saldo || event.target.value == "")  {
          i[event.target.name] = parseFloat(event.target.value);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotalesAbonos();
  };

  function ActualizaTotalesAbonos() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);
    setTotalAplicar(TE);
  }

  function savePago() {
    if (totalAplicar < total_general_edit) {
      Swal.fire({
        title: "Estas seguro?",
        text: "Se registrará el abono",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.isConfirmed) {
          toggleProgreso();
          let totalMails = inputFields.length + 1
          inputFields.map((a) => {
            if (a.importe > 0) {
              axios
                .post(
                  `${URL_ABONOS_PROVEEDORES}`,
                  {
                    fecha: endDate,
                    importe: a.importe,
                    proveedores: maquilero,
                    notasCxP: a.id,
                    observaciones: "NA",
                    codigoAgrupado,
                    editado:"No",
                    habilitacionCortes: idEdit
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  axios.patch(
                    `${URL_NOTAS_CXP}/${a.id}`,
                    {
                      saldo: a.saldo - a.importe,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                });
            } else {
              totalMails = totalMails - 1;
            }

          axios.patch(
            `${URL_HABILITACION_CORTES}AplicarSaldo/${idEdit}`,
            {
              saldo: a.importe,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          )
          .then(() => {
            totalMails = totalMails - 1;
            if (totalMails == 0) {
              Swal.fire("Good job!", "Creado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log(error);
          });

        })

        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El Importe a aplicar excede el total ",
      });
    }
  }

  function EditSalida(id) {
   
    setIdEdit(id);
    setArticulosPSEdit([])
    axios
    .get(`${URL_DETALLE_HABILITACION_CORTES}/habilitacionCorte/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allArticulosPSEdit = response.data;
      console.log("hola", id)
      let arrayTabla = allArticulosPSEdit
        .map((a) => {
          return {
            id: a._id,
            habilitacion: a.habilitacion[0].nombre + " " + a.habilitacion[0].descripcion,
            cantidad: a.cantidad,
            precio: a.precio,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let dataFinal = Object.values(arrayTabla);
      setArticulosPSEdit(dataFinal);
    })
    .catch((err) => {
      console.log(err);
    });
      toggleEditPS();
  }

  function saveHabilitacion(event) {
    event.preventDefault();
    let totalRollos = articulosPSEdit.length;

    axios
    .patch(
      `${URL_HABILITACION_CORTES}/${idEdit}`,
      // .post(
      //   URL_HABILITACION_CORTES,
        {
   
          total: totalHabilitacion,
          saldo: totalHabilitacion,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      articulosPSEdit.map((a) => {
        axios
          .patch(
            `${URL_DETALLE_HABILITACION_CORTES}/${a.id}`,
            {
              cantidad: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
                )}`,
              },
            }
          )
          .then(() => {
            totalRollos = totalRollos - 1;
            if (totalRollos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Editar Habilitacion Corte",
                    detalle: `${idEdit}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      });
  }
  const handleChangeInputHabilitacionExistentes = (id, event) => {
    const newartPSEdit = articulosPSEdit.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosPSEdit(newartPSEdit);
    ActualizaTotalesHabilitacion()
  };
  function ActualizaTotalesHabilitacion() {

    let cantTotalEx = articulosPSEdit.map((c) => parseFloat(c.cantidad) * parseFloat(c.precio));
    let TCEx = cantTotalEx.reduce((t, total, index) => t + total, 0);


    setTotalHabilitacion(TCEx);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    <Button
                      size="sm"
                      href="/MenuCXP"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Salida Habilitacion</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Salidas Habilitacion</h3>
                <Row>
                 
                  <Col md={2}>
                    <Label>Maquilero</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedMaquilero}
                      onChange={(e) => {
                        setSelectedMaquilero(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {maquileros
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.total;
                      return (
                        <tr>
                          <td>{c.numero}</td>
                          <td>{c.numeroOP}</td>
                          <td>{c.fecha}</td>
                          <td>{c.maquilero}</td>
                          <td>{c.proceso}</td>
                          <td>{c.corte}</td>
                          <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total)}</td>
                          <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.saldo)}</td>
                          <td>
                          {c.saldo == c.total ? (
                              <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditSalida(c._id)}
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>

                          ) : undefined}
                              </td>

                         <td> {c.saldo > 0 ? (
                                <Button
                                  color="primary"
                                  id="Editar"
                                  size="sm"
                                  onClick={(e) =>
                                    aplicarAnticipo(
                                      c._id,
                                      c.idMaquilero,
                                      c.maquilero,
                                      c.total,
                                      c.saldo
                                    )
                                  }
                                >
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                              ) : undefined}
                              </td>
                             
                              <td>
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  c.fecha,
                                  c.maquilero,
                                  c.maqDir,
                                  c.maqDir2,
                                  c.proceso,
                                  c.corte,
                                  c.total,
                                  c.numero,
                                  c._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                              </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}

<Modal
            size="xl"
            isOpen={modalAplicaDevolucion}
            toggle={toggleAplicaDevolucion}
          >
            <ModalHeader toggle={toggleAplicaDevolucion}>
              <h4>Aplicar Habilitacion </h4>
              <h4>Maquilero {maquileroName}</h4>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general_edit)}
                </Badge>
              </h4>
            </ModalHeader>
            <ModalBody>
              {/* <Form onSubmit={savePago}> */}

              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nota</th>
                    <th>Total</th>
                    <th>Abonos</th>
                    <th>Saldo</th>
                    <th>Importe</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                    // <div key={a.id}>
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.nota}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.total_costo
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.total_costo - a.saldo
                          )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                      </td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="number"
                          min="0"
                          step="any"
                          name="importe"
                          value={a.importe}
                          key={a.id}
                          onChange={(e) => {
                            handleChangeInputImporte(a.id, e);
                          }}
                        />
                      </td>
                     
                    </tr>
                    // </div>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="negrita" align="center">
                      Total
                    </td>
                    <td className="negrita">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAplicar)}
                    </td>
                  </tr>
                </tbody>
                <br />
              </Table>

              <br />
              {validaBoton ? (
                <Button className="btn btn-success" 
                onClick={savePago}
                >
                  {" "}
                  Registrar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              {/* </Form>      */}
            </ModalBody>
          </Modal>

           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>


          <Modal size="lg" isOpen={modalEditPS} toggle={toggleEditPS}>
        <ModalHeader toggle={toggleEditPS}>
          <h4>Editar Salida</h4>
        </ModalHeader>
        <ModalBody>
        <br />
        <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Material</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
            </Row>

            {articulosPSEdit.map((a) => (
              <div key={a.id}>
                <Row>
                  
                  <Col md={6}>
                    <Input
                      bsSize="sm"
                      name="nombre"
                      type="string"
                      value={a.habilitacion}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) => {
                        handleChangeInputHabilitacionExistentes(a.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      value={a.precio}
                      onChange={(event) => {
                        handleChangeInputHabilitacionExistentes(a.id, event);
                      }}
                    ></Input>
                  </Col>

                  
                </Row>
              </div>
            ))}
          <Row>
          {/* <Col md={9}>
            <h4 id="logoutBoton">
              TOTAL {new Intl.NumberFormat("en-US").format(totalPiezas)}{" "}
            </h4>
          </Col> */}
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={saveHabilitacion}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default SalidaHabilitacion;
