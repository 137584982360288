import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_ARTICULOS_DEVOLUCIONES =
    process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);

  const [value, setValue] = useState("");

  const [notas, setNotas] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteName, setSelectedClienteName] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [articulosNota, setArticulosNota] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");

  const [idsArticulos, setIdsArticulos] = useState([]);

  const [fecha, setFecha] = useState(hoy);

  const [apagado, setApagado] = useState(false);
  const [apagado2, setApagado2] = useState(false);

  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [impuestos, setImpuestos] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idNota: "",
      cantNota: 0,
      articulos: "",
      articulosNombre: "",
      cantidad: 0,
      defectos: 0,
      totalPiezas: 0,
      precio: 0,
      total: 0,
      colores:"",
      idColores:"",
      tallas:"",
      idTallas:""
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveDevolucion = async (event) => {
    event.preventDefault();
    if(total_cantidad > 0){
    setValidaBoton(false)
    toggleProgreso();
    let totalPedido = inputFields.length;

    try {
      await axios
        .post(
          URL_DEVOLUCIONES,
          {
            fecha,
            clientes: selectedCliente,
            colaboradores: selectedColaborador,
            total_general: parseFloat(total_general),
            observaciones,
            total_cantidad: parseFloat(total_cantidad),
            areas: selectedArea,
            subTotal,
            iva,
            impuestos,
            surtidos: selectedNota,
            aplicada: "No",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          // PDFOC(data.data._id, data.data.idDevolucion);

          inputFields.map((a) => {
            if (a.articulos != "" && a.totalPiezas != 0) {
              axios
                .post(
                  URL_ARTICULOS_DEVOLUCIONES,
                  {
                    devoluciones: data.data._id,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    defectos: parseFloat(a.defectos),
                    totalPiezas: parseFloat(a.totalPiezas),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                    areas: selectedArea,
                    tallas: a.idTallas,
                    colores:a.idColores
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response}`,
      });
      console.log(error);
    }
  }else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Debes capturar la Cantidad!",
    });
  }
  };



  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        defectos: 0,
        totalPiezas: 0,
        precio: 0,
        total: 0,
        colores:"",
        idColores:"",
        tallas:"",
        idTallas:""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    ActualizaTotales();
  };


  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp = parseFloat(event.target.value) + parseFloat(i.defectos);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.cantidad = parseFloat(event.target.value);
          i.totalPiezas =
            parseFloat(event.target.value) + parseFloat(i.defectos);
          let totalParcial =
            (parseFloat(event.target.value) + parseFloat(i.defectos)) *
            i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          i.cantidad = 0;
          i.totalPiezas = i.defectos;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputDefectos = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp = parseFloat(event.target.value) + parseFloat(i.cantidad);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.defectos = parseFloat(event.target.value);
          i.totalPiezas =
            parseFloat(event.target.value) + parseFloat(i.cantidad);
          let totalParcial =
            (parseFloat(event.target.value) + parseFloat(i.cantidad)) *
            i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          i.defectos = 0;
          i.totalPiezas = i.cantidad;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };




  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.totalPiezas));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (impuestos != 0) {
      setIva(parseFloat(((impuestos * TG) / 100).toFixed(2)));
    } else {
      setIva(0);
    }
    let totTemp = parseFloat(((impuestos * TG) / 100 + TG).toFixed(2));

    setTotalGeneral(totTemp);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
    setValue(selectedCliente);
    setSelectedCliente(selectedCliente._id);
    setSelectedClienteName(selectedCliente.nombre_comercial);
    setApagado2(true);

    setSelectedNota("");
    setArticulosNota([]);
    setIdsArticulos([]);
    setTotalGeneral(0);
    setSubTotal(0);
    setIva(0);
    setTotalCantidad(0);
    setImpuestos(0);

    setInputFields([
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        cantidad: 0,
        defectos: 0,
        totalPiezas: 0,
        precio: 0,
        total: 0,
        colores:"",
        idColores:"",
        tallas:"",
        idTallas:""
      },
    ]);

    axios
      .get(`${URL_SURTIDOS}ClienteDevolucion/${selectedCliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allNotas = res.data;
        setNotas(allNotas);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaNotas(e) {
    setSelectedNota(e);

    notas.map((a) => {
      if (a._id == e) {
        setImpuestos(a.impuestos);
      }
    });

    axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosNota = res.data;
        let arrayArticulos = allArticulosNota
        .map((a)=>{
          return{
            id: uuidv4(),
            idNota: a._id,
            cantNota: a.cantidad,
            articulos: a.articulos[0]._id,
            articulosNombre: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
            cantidad: 0,
            defectos: 0,
            totalPiezas: 0,
            precio: a.articulosPedido[0].precio,
            total: 0,
            colores: a.colores[0].name,
            idColores: a.colores[0]._id,
            tallas: a.tallas[0].name,
            idTallas: a.tallas[0]._id,
          }
        })
        setInputFields(arrayArticulos)
        setArticulosNota(allArticulosNota);
        setSelectedArea(allArticulosNota[0].surtidos[0].areas[0]._id);
        setSelectedAreaName(allArticulosNota[0].surtidos[0].areas[0].name);
        setSelectedColaborador(allArticulosNota[0].surtidos[0].pedidos[0].colaboradores[0]._id);
        let result = allArticulosNota.map((a) => a.articulos[0]._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.devoluciones_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Devolucion</h3>
          <Form onSubmit={saveDevolucion}>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                {apagado ? (
                  <Autocomplete
                    disabled
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      jalaCliente(selectedCliente);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      jalaCliente(selectedCliente);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                )}
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Notas</Label>
                {apagado ? (
                  <Input type="select" required disabled>
                    <option value="">Selecciona una nota</option>
                    {notas
                      .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.idSurtido}</option>;
                      })}
                  </Input>
                ) : (
                  <Input
                    type="select"
                    required
                    onChange={(event) => {
                      jalaNotas(event.target.value);
                    }}
                  >
                    <option value="">Selecciona una nota</option>
                    {notas
                      .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.idSurtido}</option>;
                      })}
                  </Input>
                )}
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Piezas</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas"
                  value={total_cantidad}
                  required
                  disabled
                />
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Venta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Defectuosas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total Piezas</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulosNombre"
                      type="text"
                      value={inputField.articulosNombre}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="colores"
                      type="text"
                      value={inputField.colores}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantNota"
                      type="text"
                      value={inputField.tallas}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantNota"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantNota}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="defectos"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="defectos"
                      value={inputField.defectos}
                      required
                      onChange={(event) =>
                        handleChangeInputDefectos(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="totalPiezas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total Piezas"
                      value={inputField.totalPiezas}
                      required
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoDevoluciones"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesCreate;
