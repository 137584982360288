import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function ListadoArticulosPadre() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PADRE = process.env.REACT_APP_URL_ARTICULOS_PADRE;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_TEMPORADAS = process.env.REACT_APP_URL_TEMPORADAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [is_active, setis_active] = useState("");
  const [codigo, setCodigo] = useState("");
  const [codigoCliente, setCodigoCliente] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [precioEtiqueta, setPrecioEtiqueta] = useState("");
  const [venta, setVenta] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [temporadas, setTemporadas] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [selectedCodigo, setSelectedCodigo] = useState(0);
  const [selectedCodigoCliente, setSelectedCodigoCliente] = useState(0);
  const [selectedNombre, setSelectedNombre] = useState(0);
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [selectedArticulosPadre, setSelectedArticulosPadre] = useState(0);
  const [selectedTemporada, setSelectedTemporada] = useState(0);
  const [selectedColeccion, setSelectedColeccion] = useState(0);

  const [selectedLineaEdit, setSelectedLineaEdit] = useState("");
  const [selectedTemporadaEdit, setSelectedTemporadaEdit] = useState("");
  const [selectedColeccionEdit, setSelectedColeccionEdit] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tallas, setTallas] = useState([]);
  const [selectedTalla, setSelectedTalla] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [idColoresArticulos, setIdColoresArticulos] = useState("");
  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [idTallasArticulos, setIdTallasArticulos] = useState("");
  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      cantidad:0
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      idHab: "",
      cantidad:0
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS_PADRE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              codigo: a.codigo,
              codigoCliente: a.codigoCliente,
              nombre: a.nombre,
              descripcion: a.descripcion,
              precioEtiqueta: a.precioEtiqueta,
              venta: a.venta,
              observaciones: a.observaciones,
              linea: a.lineas[0].name,
              temporada: a.temporadas[0].name,
              coleccion: a.colecciones[0].name,
              idLinea: a.lineas[0]._id,
              idTemporada: a.temporadas[0]._id,
              idColeccion: a.colecciones[0]._id,
              colores: a.colores,
              tallas : a.tallas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TEMPORADAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTemporadas = res.data;
        setTemporadas(allTemporadas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

      // axios
      // .get(URL_TELAS, {
      //   headers: {
      //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //   },
      // })
      // .then((res) => {
      //   const allTelas = res.data;
      //   setTelas(allTelas);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });

      // axios
      // .get(URL_HABILITACION, {
      //   headers: {
      //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //   },
      // })
      // .then((res) => {
      //   const allHabilitacion = res.data;
      //   setHabilitacion(allHabilitacion);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });

      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTallas = response.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedTemporada == 0 || selectedTemporada == a.idTemporada) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.codigo,
          a.codigoCliente,
          a.nombre,
          a.descripcion,
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
          "$" + new Intl.NumberFormat("en-US").format(a.precioEtiqueta),
          a.linea,
          a.temporada,
          a.coleccion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Articulos Padre", 20, 40);
    doc.autoTable({
      head: [
        [
          "Articulo Padre",
          "Codigo",
          "Codigo Cliente",
          "Nombre",
          "Descripcion",
          "Venta ",
          "Precio Etiqueta",
          "Linea",
          "Temporada",
          "Coleccion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save("ArticulosPadre.pdf");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedTemporada == 0 || selectedTemporada == a.idTemporada) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return {
          Codigo: a.codigo,
          CodigoCliente: a.codigoCliente,
          Nombre: a.nombre,
          Descripcion: a.descripcion,
          Venta: a.venta,
          PrecioEtiqueta: a.precioEtiqueta,
          Linea: a.linea,
          Temporada: a.temporada,
          Coleccion: a.coleccion,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Articulos Padre";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Articulos Padre",
        sheetFilter: [
          "Codigo",
          "CodigoCliente",
          "Nombre",
          "Descripcion",
          "Venta",
          "PrecioEtiqueta",
          "Linea",
          "Temporada",
          "Coleccion",
        ],
        sheetHeader: [
          "Codigo",
          "CodigoCliente",
          "Nombre",
          "Descripcion",
          "Venta",
          "PrecioEtiqueta",
          "Linea",
          "Temporada",
          "Coleccion",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedTemporada == 0 || selectedTemporada == a.idTemporada) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.codigo,
          a.codigoCliente,
          a.nombre,
          a.descripcion,
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
          "$" + new Intl.NumberFormat("en-US").format(a.precioEtiqueta),
          a.linea,
          a.temporada,
          a.coleccion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Articulos Padre", 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Codigo Cliente",
          "Nombre",
          "Descripcion",
          "Venta ",
          "Precio Etiqueta",
          "Linea",
          "Temporada",
          "Coleccion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Articulos Padre",
          email: mailTo,
          fileName: "ListadoArticulosPadre.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Articulos Padre.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    codigo,
    codigoCliente,
    nombre,
    descripcion,
    venta,
    precioEtiqueta,
    observaciones,
    idLinea,
    idTemporada,
    idColeccion,
    idEdit,
  ) {
    setCodigo(codigo);
    setCodigoCliente(codigoCliente);
    setNombre(nombre);
    setDescripcion(descripcion);
    setVenta(venta);
    setPrecioEtiqueta(precioEtiqueta);
    setObservaciones(observaciones);
    setSelectedLineaEdit(idLinea);
    setSelectedTemporadaEdit(idTemporada);
    setSelectedColeccionEdit(idColeccion);
    setIdEditArticulo(idEdit);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT_PADRE = `${process.env.REACT_APP_URL_ARTICULOS_PADRE}/${idEditArticulo}`;
    if (file == null) {
        axios.patch(
          URL_ARTICULOS_EDIT_PADRE,
          {
            codigo,
            codigoCliente,
            nombre,
            descripcion,
            venta,
            precioEtiqueta,
            observaciones,
            lineas: selectedLineaEdit,
            temporadas: selectedTemporadaEdit,
            colecciones: selectedColeccionEdit, 
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${nombre} ${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    } else{
        axios.patch(
          URL_ARTICULOS_EDIT_PADRE,
          {
            codigo,
            codigoCliente,
            nombre,
            descripcion,
            venta,
            precioEtiqueta,
            observaciones,
            lineas: selectedLineaEdit,
            temporadas: selectedTemporadaEdit,
            colecciones: selectedColeccionEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idEditArticulo);
  
          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Articulo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
  
              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Codigo Cliente", field: "codigoCliente", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Precio Etiqueta", field: "precioEtiqueta", sortable: true },
    { name: "Temporada", field: "temporada", sortable: true },
    { name: "Linea", field: "linea", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment._id.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigoCliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.temporada.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.venta.toString().includes(search) ||
          comment.precioEtiqueta.toString().includes(search)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedCodigoCliente) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedNombre)
      );
    }
    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }
    if (selectedTemporada) {
      computedComments = computedComments.filter((e) =>
        e.idTemporada.includes(selectedTemporada)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (is_active) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(is_active)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "venta" &&
      sorting.field != "precioEtiqueta"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      sorting.field == "venta" &&
      sorting.field == "precioEtiqueta" 
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      sorting.field == "venta" &&
      sorting.field == "precioEtiqueta"
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCodigo,
    selectedCodigoCliente,
    selectedNombre,
    selectedLinea,
    selectedTemporada,
    selectedColeccion,
    is_active,
  ]);

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
  };

  const handleAddFieldsTelas = () => {
    setInputFieldsTela([
      ...inputFieldsTela,
      {
        id: uuidv4(),
        idTela: "",
        cantidad:0
      },
    ]);
  };

  const handleAddFieldsHab = () => {
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        idHab: "",
        cantidad:0
      },
    ]);
  };

  const handleChangeInputTela = (id, event) => {
    const newInputFieldsTela = inputFieldsTela.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTela(newInputFieldsTela);
  };

  const handleChangeInputHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
  };

function coloresInfo(idColores, codigo, nombre, coloresInfo) {
  let ids = coloresInfo.map(function (e) {
    return e._id;
  });
  let coloresDany = Object.values(ids);

  setIdColoresArticulos(idColores);
  setCodigo(codigo);
  setNombre(nombre);
  setArrayColores(coloresDany);
  toggleColores();
}

function coloresSeleccionados(checked, idColor) {
  if (checked) {
    arrayColores.push(idColor);
    console.log(arrayColores);
  } else {
    const index = arrayColores.indexOf(idColor);
    if (index > -1) {
      arrayColores.splice(index, 1);
      console.log(arrayColores);
    }
  }
}

function guardarColores() {
  const URL_ARTICULOS_COLORES = `${process.env.REACT_APP_URL_ARTICULOS_PADRE}/${idColoresArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_COLORES,
      {
        colores: arrayColores,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "Colores Articulo",
          detalle: `${nombre} ${codigo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function tallasInfo(idTallas, codigo, nombre, tallasInfo) {
  let ids = tallasInfo.map(function (e) {
    return e._id;
  });
  let tallasDany = Object.values(ids);

  setIdTallasArticulos(idTallas);
  setCodigo(codigo);
  setNombre(nombre);
  setArrayTallas(tallasDany);
  toggleTallas();
}

function tallasSeleccionados(checked, idTalla) {
  if (checked) {
    arrayTallas.push(idTalla);
    console.log(arrayTallas);
  } else {
    const index = arrayTallas.indexOf(idTalla);
    if (index > -1) {
      arrayTallas.splice(index, 1);
      console.log(arrayTallas);
    }
  }
}

function guardarTallas() {
  const URL_ARTICULOS_TALLAS = `${process.env.REACT_APP_URL_ARTICULOS_PADRE}/${idTallasArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_TALLAS,
      {
        tallas: arrayTallas,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "Tallas Articulo",
          detalle: `${nombre} ${codigo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function jalaFoto(
  idEdit
) {
  setPhoto("")
  const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
  axios
    .get(URL_GET_MEDIA, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((data) => {
      if (data.data.version) {
        setPhoto(data.data.version);
      }
    })
    .catch((err) => console.log(err));


  setIdEditArticulo(idEdit);
  toggleFoto();
}
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosPadreCreate"
                >
                  Nuevo Articulo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Articulo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>
                Enviar Articulos Padre
              </h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Articulos Padre</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}></td>
             
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigoCliente}
                  onChange={(e) => {
                    setSelectedCodigoCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.codigoCliente > b.codigoCliente ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigoCliente}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedTemporada}
                  onChange={(e) => {
                    setSelectedTemporada(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {temporadas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.is_active}</td>
                      <td>{a.codigo}</td>
                      <td>{a.codigoCliente}</td>
                      <td>{a.nombre}</td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.venta)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.precioEtiqueta)}
                      </td>
                      <td>{a.temporada} </td>
                      <td>{a.linea} </td>
                      <td>{a.coleccion} </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                              <Button
                              color="success"
                              id="Colores"
                              size="sm"
                              onClick={(e) =>
                                coloresInfo(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.colores
                                )
                              }
                            >
                            <i class="fas fa-palette"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Tallas"
                              size="sm"
                              onClick={(e) =>
                                tallasInfo(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.tallas
                                )
                              }
                            >
                            <i class="fas fa-ruler-horizontal"></i>
                            </Button>
                           
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.codigoCliente,
                                  a.nombre,
                                  a.descripcion,
                                  a.venta,
                                  a.precioEtiqueta,
                                  a.observaciones,
                                  a.idLinea,
                                  a.idTemporada,
                                  a.idColeccion,
                                  a._id,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.is_active}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS_PADRE}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Articulos Padre {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
        
              <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Codigo Cliente</Label>
                <Input
                  type="text"
                  placeholder="CodigoCliente"
                  value={codigoCliente}
                  required
                  onChange={(e) => {
                    setCodigoCliente(e.target.value);
                  }}
                />
              </Col>
              {/* <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col> */}

              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>

              {/* </Row>
              <Row> */}
              {/* <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="text"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Precio Etiqueta</Label>
                <Input
                  type="text"
                  placeholder="Precio Etiqueta"
                  value={precioEtiqueta}
                  required
                  onChange={(e) => {
                    setPrecioEtiqueta(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Temporada</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTemporadaEdit}
                  required
                  onChange={(e) => {
                    setSelectedTemporadaEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {temporadas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col> */}
              
              
              <Col md={3}>
                <Label className="mr-sm-2">Linea</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedLineaEdit}
                  required
                  onChange={(e) => {
                    setSelectedLineaEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              
              {/* <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccionEdit}
                  required
                  onChange={(e) => {
                    setSelectedColeccionEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col> */}
              
              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            
              </Row>
              <Row>
              <Col md={3}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
              </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
        <ModalHeader toggle={toggleColores}>
          <h4>
            Colores del Articulo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          {colores
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayColores.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          coloresSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarColores}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
        <ModalHeader toggle={toggleTallas}>
          <h4>
            Tallas del Articulo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          {tallas
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayTallas.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          tallasSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarTallas}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/frateloArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoArticulosPadre;
