import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";

function BonificacionesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_BONIFICACIONES = process.env.REACT_APP_URL_BONIFICACIONES;
  const URL_CONCEPTOS_BONIFICACIONES =
    process.env.REACT_APP_URL_CONCEPTOS_BONIFICACIONES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [conceptosBonificaciones, setConceptosBonificaciones] = useState([]);
  const [selectedConceptosBonificaciones, setSelectedConceptosBonificaciones] =
    useState("");
  const [total, setTotal] = useState(0);

  const [selectedNota, setSelectedNota] = useState("");
  const [selectedNotaInput, setSelectedNotaInput] = useState("");
  const [totalNota, setTotalNota] = useState(0);
  const [saldoNota, setSaldoNota] = useState(0);

  const [notas, setNotas] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      concepto: "",
      porcentaje: 0,
      importe: 0,
      observaciones: "NA",
    },
  ]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CONCEPTOS_BONIFICACIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConceptosBonificaciones = response.data;
        setConceptosBonificaciones(allConceptosBonificaciones);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_SURTIDOS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.idSurtido,
              importe: 0,
              clientes: a.clientes[0]._id,
              observaciones: "NA",
              movimiento: "Nota",
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setNotas(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCliente]);

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.importe = parseFloat(event.target.value);
        i.porcentaje = (
          (parseFloat(event.target.value) * 100) /
          totalNota
        ).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPorcentaje = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.porcentaje = parseFloat(event.target.value);
        i.importe = (
          (parseFloat(event.target.value) * totalNota) /
          100
        ).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    setTotal(TE);
  }

  function savePago() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará la bonificacion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = inputFields.length;
        inputFields.map((a) => {
          if (a.importe > 0) {
            axios
              .post(
                URL_BONIFICACIONES,
                {
                  fecha,
                  importe: a.importe,
                  porcentaje: a.porcentaje,
                  clientes: selectedCliente,
                  surtidos: selectedNota,
                  conceptosBonificaciones: a.concepto,
                  observaciones: a.observaciones,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
               totalMails = totalMails - 1;
                if (totalMails == 0) {
                  axios.patch(
                    `${URL_SURTIDOS}/${selectedNota}`,
                    {
                      saldo: saldoNota - total,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  ).then(()=>{
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });

                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  // footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalMails = totalMails - 1;
          }
        });
      }
    });
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaNota(value) {
    let split = value.split(",");
    let id = split[0];
    let total = split[1];
    let saldo = split[2];

    setSelectedNotaInput(value);
    setSelectedNota(id);
    setSaldoNota(saldo);
    setTotalNota(total);
  }

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        concepto: "",
        porcentaje: 0,
        importe: 0,
        observaciones: "NA",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura una Bonificacion</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={3}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    placeholder="Fecha"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteNombre(
                          selectedCliente.nombre_comercial
                        );
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>

                <Col md={3}>
                  <Label className="mr-sm-2">Nota</Label>
                  <Input
                    type="select"
                    value={selectedNotaInput}
                    onChange={(e) => {
                      jalaNota(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {notas
                      .sort((a, b) => (a.nota < b.nota ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={[a.id, a.total_general, a.saldo]}>
                            {a.nota} / ${a.total_general}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h4 align="right">
                    Total Pago
                    {"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {"$" + new Intl.NumberFormat("en-US").format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th>Porcentaje</th>
                      <th>Importe</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>
                          <Input
                            // bsSize="sm"
                            name="concepto"
                            type="select"
                            value={a.concepto}
                            required
                            onChange={(event) => {
                              handleChangeInput(a.id, event);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {conceptosBonificaciones.map((cb) => {
                              return (
                              <option value={cb._id}>{cb.name}</option>
                              )
                            })}
                          </Input>
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="porcentaje"
                            value={a.porcentaje}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputPorcentaje(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="importe"
                            value={a.importe}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputImporte(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            disabled={inputFields.length === 1}
                            onClick={() => handleRemoveFields(a.id)}
                            tabindex="-1"
                          >
                            <i class="fas fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={handleAddFields}
                            tabindex="-1"
                          >
                            <i class="fas fa-plus"></i>
                          </Button>
                        </td>
                      </tr>
                      // </div>
                    ))}

                    <tr>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {"$" + new Intl.NumberFormat("en-US").format(total)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoBonificaciones"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default BonificacionesCreate;
