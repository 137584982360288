import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoAbonosFacturas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ABONOS_FACTURAS = process.env.REACT_APP_URL_ABONOS_FACTURAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [abonosFacturas, setAbonosFacturas] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [contactos, setContactos] = useState([]);
  const [selectedContacto, setSelectedContacto] = useState("");
  const [forma_pago, setFormaPago] = useState(""); 
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [value, setValue] = useState();

  const [idAbonoEdit, setIdAbonoEdit] = useState();
  const [numeroEdit, setNumeroEdit] = useState();
  const [fechaEdit, setFechaEdit] = useState();
  const [clientesEdit, setClientesEdit] = useState();
  const [facturasEdit, setFacturasEdit] = useState();
  const [importeEdit, setImporteEdit] = useState();
  const [forma_pago_edit, setFormaPagoEdit] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);
  const [valueEdit, setValueEdit] = useState("");


  useEffect(() => {
    axios
      .get(URL_ABONOS_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosFacturas = response.data;
        setAbonosFacturas(allAbonosFacturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let total = 0;

  // PDF
  let totalAbonos = 0;


  function PDFTabla() {
    totalAbonos = 0;
    const data = abonosFacturas.map((a) => {
      if (
        (forma_pago == 0 || forma_pago == a.forma_pago) &&
        (value == null || value._id == a.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.clientes[0].colaboradores[0]._id)&&
          (selectedContacto == 0 ||
            selectedContacto ==
              a.clientes[0].contacto[0]._id)
      ) {
        totalAbonos = totalAbonos + a.importe;
        return [
          a.fecha,
          a.clientes[0].nombre + " " + a.clientes[0].apellido,
          [ a.clientes[0].colaboradores[0].nombre +" " + a.clientes[0].colaboradores[0].apellido,],
          a.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(a.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Abonos Notas ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Nombre", "Cobrador", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`AbonosNotas.pdf`);
  }

  function excel() {
    const dataExcel = abonosFacturas.map((c) => {
      if (
        (forma_pago == 0 || forma_pago == c.forma_pago) &&
        (value == null || value._id == c.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.clientes[0].colaboradores[0]._id)&&
          (selectedContacto == 0 ||
            selectedContacto ==
              c.clientes[0].contacto[0]._id)
      ) {
        return {
          Fecha: c.fecha,
          Nombre: c.clientes[0].nombre + " " + c.clientes[0].apellido,
          Cobrador:
            c.clientes[0].colaboradores[0].nombre +
            " " +
            c.clientes[0].colaboradores[0].apellido,
            Forma: c.forma_pago,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosNotas",
        sheetFilter: ["Fecha", "Nombre", "Cobrador", "Forma", "Importe"],
        sheetHeader: ["Fecha", "Nombre", "Cobrador", "Forma", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail(){
    totalAbonos = 0;
    const data = abonosFacturas.map((a) => {
      if (
        (forma_pago == 0 || forma_pago == a.forma_pago) &&
        (value == null || value._id == a.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.clientes[0].colaboradores[0]._id) &&
          (selectedContacto == 0 ||
            selectedContacto ==
              a.clientes[0].contacto[0]._id)
      ) {
        totalAbonos = totalAbonos + a.importe;
        return [
          a.fecha,
          a.clientes[0].nombre + " " + a.clientes[0].apellido,
          [ a.clientes[0].colaboradores[0].nombre +" " + a.clientes[0].colaboradores[0].apellido,],
          a.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(a.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Abonos Notas ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Nombre", "Cobrador", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Detalle Abonos Noats',
         email: mailTo,
         fileName: 'Detalle-Abonos-Noats.pdf',
         att: base64File,
         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el detalle de los abonos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
setTimeout(() => {
      window.location.reload();
    }, 1000);
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }

  const options = clientes.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const optionsEdit = clientes.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function PDFRecibo(idAbonoFactura, fecha, nombre, importe) {
    let idAbonoFacturaRecibo = idAbonoFactura
    let fechaRecibo = fecha
    let nombreRecibo = nombre
    let importeRecibo = importe
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img, "png", 150, 10, 35, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img2, "png", 140, 20, 60, 25);
                                  // Horizontal, Vertical
    doc.setFontSize(16)
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`,65,65);
    doc.text(`__________________`,80,100);
    doc.text(`Firma`,100,110);
    doc.autoTable({
      head: [["No Recibo","Fecha", "Cliente", "Importe"]],
      body: [[idAbonoFacturaRecibo, fechaRecibo, nombreRecibo, "$" + new Intl.NumberFormat("en-US").format(importeRecibo)]],
      startY: 70,
    });
    doc.save(`Recibo-${idAbonoFacturaRecibo}.pdf`);
  } 

  function EditAbono(id, idAbonoFactura, fecha, clientes, importe, forma_pago, facturas){
    setIdAbonoEdit(id)
    setNumeroEdit(idAbonoFactura)
    setFechaEdit(fecha)
    setClientesEdit(clientes)
    setImporteEdit(importe)
    setFormaPagoEdit(forma_pago)
    setValueEdit(clientes)
    setFacturasEdit(facturas)
    console.log(facturas)
    toggleEdit()
  }

  const EditAbonoSave = (event) => {
    event.preventDefault();
    setValidaBoton(false)
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        // Horizontal, Vertical, Ancho, Alto
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        // Horizontal, Vertical, Ancho, Alto
        doc.addImage(img2, "png", 140, 20, 60, 25);
        // Horizontal, Vertical
        doc.setFontSize(16);
        doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 65, 65);
        doc.text(`__________________`, 80, 100);
        doc.text(`Firma`, 100, 110);
        doc.autoTable({
          head: [["No Recibo", "Fecha", "Cliente", "Importe"]],
          body: [
            [
              numeroEdit,
              fechaEdit,
              [valueEdit.nombre + " " + valueEdit.apellido],
              "$" + new Intl.NumberFormat("en-US").format(importeEdit),
            ],
          ],
          startY: 70,
        });

        var att = doc.output("arraybuffer");
        var base64File = encode.encode(att, "base64");
        
        axios.patch(
            `${URL_ABONOS_FACTURAS}/${idAbonoEdit}`,
            {
              fecha:fechaEdit,
              importe:importeEdit,
              forma_pago:forma_pago_edit,
              facturas:facturasEdit
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              `${URL_ABONOS_FACTURAS}/mail`,
              {
                fecha:fechaEdit,
                importe:importeEdit,
                clientes: clientesEdit,
                base64File,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Abono Nota",
                detalle: `${valueEdit._id}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
        }
            )
          .catch((err) => {
            console.log(err);
          });
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              doc.save(`Recibo-${numeroEdit}.pdf`);
              // clear()
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
  };

  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_facturas ? (
        <div className="card container">
          <Row>
            <Col sm={8}>
              {user.facturas_abonos_create ? (
                <Button
                  size="sm"
                  href="/AbonosNotasCreate"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuClientes"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Detalle de Abonos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                      <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>


          <h3 align="center">Detalle Abonos</h3>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Table size="sm" striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th style={{ paddingBottom: "0px" }}>Numero</th>
                <th style={{ paddingBottom: "0px" }}>Fecha</th>
                <th style={{ paddingBottom: "0px" }}>Cliente</th>
                <th style={{ paddingBottom: "0px" }}>Contacto</th>
                <th style={{ paddingBottom: "0px" }}>Cobrador</th>
                <th style={{ paddingBottom: "0px" }}>Forma de Pago</th>
                <th style={{ paddingBottom: "0px" }}>Nota</th>
                <th style={{ paddingBottom: "0px" }}>Importe</th>
                <th style={{ paddingBottom: "0px" }}>Detalles</th>
                <th style={{ paddingBottom: "0px" }}>Editar</th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td style={{ paddingTop: "0px" }}>
                        <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedCliente) => {
                            setValue(selectedCliente);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        />
                      </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedContacto}
                    required
                    onChange={(e) => setSelectedContacto(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {colaboradores
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}{" "}
                          </option>
                        );
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    required
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        if (a.cobrador == "Si") {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        }
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </td>
              </tr>
            </thead>
            <tbody>
              {abonosFacturas
                .sort((a, b) => (a.idAbonoFactura < b.idAbonoFactura ? 1 : -1))
                .map((c) => {
                  if (
                    (forma_pago == 0 || forma_pago == c.forma_pago) &&
                    (selectedFechaInicio == 0 ||
                      selectedFechaInicio <= c.fecha) &&
                    (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                    (selectedColaborador == 0 ||
                      selectedColaborador ==
                        c.clientes[0].colaboradores[0]._id)&&
                        (selectedContacto == 0 ||
                          selectedContacto ==
                            c.clientes[0].contacto[0]._id) &&
                            (value == null || value._id == c.clientes[0]._id)
                  ) {
                    total = total + c.importe;
                    return (
                      <tr>
                        <td>{"# " + c.idAbonoFactura}</td>
           <td>{c.fecha}</td> 
           <td>{c.clientes[0].nombre} {c.clientes[0].apellido} </td>
           <td>{c.clientes[0].contacto[0].nombre + " " + c.clientes[0].contacto[0].apellido}</td>
           <td>{c.clientes[0].colaboradores[0].nombre + " " + c.clientes[0].colaboradores[0].apellido}</td>
           <td>{c.forma_pago}</td>
           <td>{c.facturas[0].nota}</td>
           <td>{"$" + new Intl.NumberFormat("en-US").format(c.importe)}</td>
           <td><Button size="sm" className="btn" color="danger" 
            onClick={(e)=>PDFRecibo(c.idAbonoFactura, c.fecha, c.clientes[0].nombre + " " + c.clientes[0].apellido, c.importe)}
            >
            <i class="far fa-file-pdf"></i>
          </Button>
          </td>
          <td>
          {user.facturas_abonos_edit ? (
            <Button size="sm" className="btn" color="info" 
            onClick={(e)=>EditAbono(c._id, c.idAbonoFactura, c.fecha, c.clientes[0]._id, c.importe, c.forma_pago, c.facturas[0]._id)}
            >
            <i class="far fa-edit"></i>
          </Button>
          ):(
            <Button size="sm" className="btn" color="info" disabled >
            <i class="far fa-edit"></i>
          </Button>
          )}
          </td>
                      </tr>
                    );
                  }
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
                      <ModalHeader toggle={toggleEdit}><h4>Editar Abono</h4></ModalHeader>
                      <ModalBody>
            <Form onSubmit={EditAbonoSave}>
              <Row>
                <Col md={6}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fechaEdit}
                    required
                    onChange={(e) => {
                      setFechaEdit(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importeEdit}
                    required
                    onChange={(e) => {
                      setImporteEdit(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Forma de Pago</Label>
                  <Input
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago_edit}
                    required
                    onChange={(e) => {
                      setFormaPagoEdit(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </Col>
              </Row>

              <br />
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                 Guardar
                </Button>
                ):(
                <Button type="submit" className="btn btn-success" disabled>
                 Guardar
                </Button>)}
                
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
              </Row>
            </Form>
            </ModalBody>
                      
                    </Modal>

        </div>
      ) : undefined}
    </>
  );
}

export default ListadoAbonosFacturas;
