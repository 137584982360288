import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";
import moment from 'moment';


let date = new Date();
let hoy = moment(date).format("YYYY-MM-DD");
let numeroSemana = moment(hoy).week(); // Obtener el número de la semana
function PendientesCxP() {
  const { user } = useContext(AuthContext);
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_HABILITACION_CORTES = process.env.REACT_APP_URL_HABILITACION_CORTES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  
  const [notas, setNotas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedSemana, setSelectedSemana] = useState(numeroSemana);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(""); 

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
   axios
      .get(`${URL_NOTAS_CXP}PendientesCxP`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNotas = response.data;
        setNotas(allNotas);
        setComments(allNotas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);
console.log("hola", comments)
  function PDFTabla() {
    let subtotalTablaPDF = 0;
    let habilitacionTablaPDF = 0;
    let totalTablaPDF = 0;
    let entregadoTablaPDF = 0;
    let montoTablaPDF = 0;
    let pagadoTablaPDF = 0;
    let saldoTablaPDF = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fechaSalida) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fechaSalida) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)&&
        (selectedSemana == 0 || selectedSemana == a.numeroSemana)
      ) {
        subtotalTablaPDF = subtotalTablaPDF + a.subtotal
        habilitacionTablaPDF = habilitacionTablaPDF + a.totalHabilitacion
        totalTablaPDF = totalTablaPDF + a.total
        entregadoTablaPDF = entregadoTablaPDF + a.entregado
        montoTablaPDF = montoTablaPDF + a.monto
        pagadoTablaPDF = pagadoTablaPDF + a.pagado
        saldoTablaPDF = saldoTablaPDF + a.saldo
        return [
          a.maquilero,
          a.fechaSalida,
          a.articulo,
          a.ordenProduccion,
          new Intl.NumberFormat("en-US").format(a.cantidadSalida),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subtotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalHabilitacion),
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.porcentaje) + " %",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoPz),
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.entregado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.monto),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.pagado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Pendientes CxP`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Maquilero",
          "Fecha",
          "Articulo",
          "OP",
          "Cantidad",
          "Costo",
          "Subtotal",
          "Habilitacion",
          "Porcentaje",
          "Total",
          "Costo x Pz",
          "Entregado",
          "Monto",
          "A Cuenta",
          "Saldo"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subtotalTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(habilitacionTablaPDF),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTablaPDF),
          "",
          new Intl.NumberFormat("en-US").format(entregadoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(montoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(pagadoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoTablaPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`PendientesCxP.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fechaSalida) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fechaSalida) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)&&
        (selectedSemana == 0 || selectedSemana == a.numeroSemana)
      ) {
        return {
          Maquilero: a.maquilero,
          Fecha: a.fechaSalida,
          Articulo: a.articulo,
          OP: a.ordenProduccion,
          Cantidad: a.cantidadSalida,
          Costo: a.costo,
          Subtotal: a.subtotal,
          Habilitacion: a.totalHabilitacion,
          Porcentaje: a.porcentaje,
          Total: a.total,
          CostoPz: a.costoPz,
          Entregado: a.entregado,
          Monto: a.monto,
          ACuenta: a.pagado,
          Saldo: a.saldo
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPendientesCxP";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPendientesCxP",
        sheetFilter: [
          "Maquilero",
          "Fecha",
          "Articulo",
          "OP",
          "Cantidad",
          "Costo",
          "Subtotal",
          "Habilitacion",
          "Porcentaje",
          "Total",
          "CostoPz",
          "Entregado",
          "Monto",
          "ACuenta",
          "Saldo",
        ],
        sheetHeader: [
          "Maquilero",
          "Fecha",
          "Articulo",
          "OP",
          "Cantidad",
          "Costo",
          "Subtotal",
          "Habilitacion",
          "Porcentaje",
          "Total",
          "CostoPz",
          "Entregado",
          "Monto",
          "ACuenta",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let subtotalTablaPDF = 0;
    let habilitacionTablaPDF = 0;
    let totalTablaPDF = 0;
    let entregadoTablaPDF = 0;
    let montoTablaPDF = 0;
    let pagadoTablaPDF = 0;
    let saldoTablaPDF = 0;
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fechaSalida) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fechaSalida) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)&&
        (selectedSemana == 0 || selectedSemana == a.numeroSemana)
      ) {
        subtotalTablaPDF = subtotalTablaPDF + a.subtotal
        habilitacionTablaPDF = habilitacionTablaPDF + a.totalHabilitacion
        totalTablaPDF = totalTablaPDF + a.total
        entregadoTablaPDF = entregadoTablaPDF + a.entregado
        montoTablaPDF = montoTablaPDF + a.monto
        pagadoTablaPDF = pagadoTablaPDF + a.pagado
        saldoTablaPDF = saldoTablaPDF + a.saldo
        return [
          a.maquilero,
          a.fechaSalida,
          a.articulo,
          a.ordenProduccion,
          new Intl.NumberFormat("en-US").format(a.cantidadSalida),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subtotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalHabilitacion),
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.porcentaje) + " %",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoPz),
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.entregado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.monto),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.pagado),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Pendientes CxP`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Maquilero",
          "Fecha",
          "Articulo",
          "OP",
          "Cantidad",
          "Costo",
          "Subtotal",
          "Habilitacion",
          "Porcentaje",
          "Total",
          "Costo x Pz",
          "Entregado",
          "Monto",
          "A Cuenta",
          "Saldo"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subtotalTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(habilitacionTablaPDF),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTablaPDF),
          "",
          new Intl.NumberFormat("en-US").format(entregadoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(montoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(pagadoTablaPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoTablaPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Pendientes CxP",
          email: mailTo,
          fileName: "ListadoPendientesCxP.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pendientes CxP.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Maquilero", field: "maquilero", sortable: true },
    { name: "Fecha", field: "fechaSalida", sortable: true },
    { name: "Semana", field: "numeroSemana", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "OP", field: "ordenProduccion", sortable: true },
    { name: "Cantidad", field: "cantidadSalida", sortable: true },
    { name: "Costo", field: "costo", sortable: true },
    { name: "SubTotal", field: "subtotal", sortable: true },
    { name: "Habilitacion", field: "totalHabilitacion", sortable: true },
    { name: "Porcentaje", field: "porcentaje", sortable: true },
    { name: "Total", field: "total", sortable: true },
    { name: "Costo x Pz", field: "costoPz", sortable: true },
    { name: "Entregado", field: "entregado", sortable: true },
    { name: "Monto", field: "monto", sortable: true },
    { name: "A cuenta", field: "pagado", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.maquilero.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroSemana.toLowerCase().includes(search.toLowerCase()) ||
          comment.ordenProduccion.toString().includes(search) ||
          comment.cantidadSalida.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.subtotal.toString().includes(search) ||
          comment.entregado.toString().includes(search) ||
          comment.pagado.toString().includes(search) ||
          comment.totalHabilitacion.toString().includes(search) ||
          comment.porcentaje.toString().includes(search) ||
          comment.total.toString().includes(search) ||
          comment.costoPz.toString().includes(search) ||
          comment.monto.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idMaquilero.includes(selectedProveedor)
      );
    }
    if (selectedSemana) {
      computedComments = computedComments.filter((e) =>
        e.numeroSemana.includes(selectedSemana)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "ordenProduccion" &&
      sorting.field != "cantidadSalida" &&
      sorting.field != "costo" &&
      sorting.field != "subtotal" &&
      sorting.field != "pagado" &&
      sorting.field != "saldo" &&
      sorting.field != "totalHabilitacion" &&
      sorting.field != "porcentaje" &&
      sorting.field != "total" &&
      sorting.field != "costoPz" &&
      sorting.field != "monto" &&
      sorting.field != "entregado" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "ordenProduccion" ||
        sorting.field == "cantidadSalida" ||
        sorting.field == "costo" ||
        sorting.field == "subtotal" ||
        sorting.field == "pagado" ||
        sorting.field == "saldo" ||
        sorting.field == "totalHabilitacion" ||
        sorting.field == "porcentaje" ||
        sorting.field == "total" ||
        sorting.field == "costoPz" ||
        sorting.field == "monto" ||
        sorting.field == "entregado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "ordenProduccion" ||
        sorting.field == "cantidadSalida" ||
        sorting.field == "costo" ||
        sorting.field == "subtotal" ||
        sorting.field == "pagado" ||
        sorting.field == "saldo" ||
        sorting.field == "totalHabilitacion" ||
        sorting.field == "porcentaje" ||
        sorting.field == "total" ||
        sorting.field == "costoPz" ||
        sorting.field == "monto" ||
        sorting.field == "entregado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    selectedSemana,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  let subtotalTabla = 0;
  let habilitacionTabla = 0;
  let totalTabla = 0;
  let entregadoTabla = 0;
  let montoTabla = 0;
  let pagadoTabla = 0;
  let saldoTabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_adminCxP ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCXP"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pendientes CxP</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pendientes de Pago</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Semana</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedSemana}
                onChange={(e) => {
                  setSelectedSemana(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>

              </Input>
            </Col>
            <Col md={2}>
              <Label>Maquilero</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    if(a.tipo =="Maquilero"){
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  }})}
              </Input>
            </Col>

          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                subtotalTabla = subtotalTabla + a.subtotal
                habilitacionTabla = habilitacionTabla + a.totalHabilitacion
                totalTabla = totalTabla + a.total
                entregadoTabla = entregadoTabla + a.entregado
                montoTabla = montoTabla + a.monto
                pagadoTabla = pagadoTabla + a.pagado
                saldoTabla = saldoTabla + a.saldo
                {
                  return (
                    <tr>
                      <td>{a.maquilero}</td>
                      <td>{a.fechaSalida}</td>
                      <td>{a.numeroSemana}</td>
                      <td>{a.articulo}</td>
                      <td>{a.ordenProduccion}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidadSalida)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subtotal)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.totalHabilitacion)}</td>
                      <td>{new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(a.porcentaje)}%</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoPz)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.entregado)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.monto)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.pagado)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}</td>
                      {/* <td>
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.totalCantidad,
                                  a.observaciones,
                                  a.contenedor,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                          </div>
                      </td> */}
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTALES
                </td><td className="negrita">
                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subtotalTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(habilitacionTabla)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(entregadoTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(montoTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(pagadoTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoTabla)}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

     
      {loader}
    </>
  );
}

export default PendientesCxP;
