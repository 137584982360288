import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { func } from "prop-types";
import qrcode from "qrcode-js";

function ListadoInventariosTelas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS_TELAS = `${process.env.REACT_APP_URL_INVENTARIOS_TELAS}`;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;

  const [inventariosTelas, setInventariosTelas] = useState([]);
  const [inventariosTelasRollos, setInventariosTelasRollos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedComposicion, setSelectedComposicion] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [articulosComposicion, setArticulosComposicion] = useState([]);
  const [articuloDesgloce, setArticuloDesgloce] = useState("");
  const [nombreDesgloce, setNombreDesgloce] = useState("");
  const [codigoDesgloce, setCodigoDesgloce] = useState("");
  const [colorDesgloce, setColorDesgloce] = useState("");
  const [pesoDesgloce, setPesoDesgloce] = useState("");
  const [composicionDesgloce, setComposicionDesgloce] = useState("");
  const [unidadDesgloce, setUnidadDesgloce] = useState("");
  const [anchoDesgloce, setAnchoDesgloce] = useState("");
  const [tiposTelasDesgloce, setTiposTelasDesgloce] = useState("");

  const [articulosCodigos, setArticulosCodigos] = useState([]);
  const [articulosNombre, setArticulosNombre] = useState([]);
  const [selectedCodigo, setSelectedCodigo] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");

  const [idRolloEdit, setIdRolloEdit] = useState("");
  const [articuloEdit, setArticuloEdit] = useState("");
  const [unidadEdit, setUnidadEdit] = useState("");
  const [cantidadEdit, setCantidadEdit] = useState("");
  const [cantidadAnteriorEdit, setCantidadAnteriorEdit] = useState("");
  const [produccionEdit, setProduccionEdit] = useState("No");
  const [apartadoEdit, setApartadoEdit] = useState("No");
  const [idCompraEdit, setIdCompraEdit] = useState("");
  const [numeroCompraEdit, setNumeroCompraEdit] = useState("");

  const [idRolloHistorial, setIdRolloHistorial] = useState("");
  const [articuloHistorial, setArticuloHistorial] = useState("");
  const [unidadHistorial, setUnidadHistorial] = useState("");
  const [cantidadHistorial, setCantidadHistorial] = useState("");
  const [cantidadAnteriorHistorial, setCantidadAnteriorHistorial] = useState("");
  const [produccionHistorial, setProduccionHistorial] = useState("No");
  const [idCompraHistorial, setIdCompraHistorial] = useState("");
  const [numeroCompraHistorial, setNumeroCompraHistorial] = useState("");
  const [surtidoHistorial, setSurtidoHistorial] = useState("");

  const [unidad, setUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalMailRollo, setModalMailRollo] = useState(false);
  const toggleMailRollo = () => setModalMailRollo(!modalMailRollo);

  const [modalDesgloce, setModalDesgloce] = useState(false);
  const toggleDesgloce = () => setModalDesgloce(!modalDesgloce);

  const [modalEditRollo, setModalEditRollo] = useState(false);
  const toggleEditRollo = () => setModalEditRollo(!modalEditRollo);

  const [modalCortarRollo, setModalCortarRollo] = useState(false);
  const toggleCortarRollo = () => setModalCortarRollo(!modalCortarRollo);
  const [cantidadCortar, setCantidadCortar] = useState("");
  const [rolloCortar, setRolloCortar] = useState("");

  const [modalHistorialRollo, setModalHistorialRollo] = useState(false);
  const toggleHistorialRollo = () =>
    setModalHistorialRollo(!modalHistorialRollo);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_INVENTARIOS_TELAS}Acumulado`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventariosTelas = response.data;
        // Array para pagination
        let arrayTabla = allInventariosTelas
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              cantidad: a.cantidad,
              codigo: a.codigo,
              nombre: a.nombre,
              idTela: a.idTela,
              composicion: a.composicion,
              descripcion: a.descripcion,
              unidad: a.unidad,
              ancho: a.ancho,
              peso: a.peso,
              rendimiento: a.rendimiento,
              idTiposTelas: a.idTiposTelas,
              tiposTelas: a.tiposTelas,
              costo: a.costo,
              costoTotal: a.costoTotal,
              observaciones: a.observaciones,
              colores: a.colores,
              idColores: a.idColores,
              idCompras: a.idCompras,
              rollos: parseFloat(a.rollos),
              idProveedor: a.idProveedor,
              proveedor: a.proveedor,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setInventariosTelas(dataFinal);
        setComments(dataFinal);

        var items = dataFinal.map((a) => {
          return a.composicion;
        });

        const unicos = [];
        for (var i = 0; i < items.length; i++) {
          const elemento = items[i];

          if (!unicos.includes(items[i])) {
            unicos.push(elemento);
          }
        }
        setArticulosComposicion(unicos);

        var itemsCodigos = dataFinal.map((a) => {
          return a.codigo;
        });

        let agrupadoCodigos = dataFinal.reduce(function (groups, item) {
          const val = item["codigo"];
          groups[val] = groups[val] || {
            codigo: item.codigo,
            cantidad: 0,
          };
          groups[val].codigo = item.codigo;
          groups[val].idTela = item.idTela;
          groups[val].cantidad += item.cantidad;
          return groups;
        }, []);
        let dataFinalCodigos = Object.values(agrupadoCodigos);
        setArticulosCodigos(dataFinalCodigos);

        let agrupadoNombres = dataFinal.reduce(function (groups, item) {
          const val = item["nombre"];
          groups[val] = groups[val] || {
            nombre: item.nombre,
            idTela: "",
          };
          groups[val].nombre = item.nombre;
          groups[val].idTela = item.idTela;
          return groups;
        }, []);
        let dataFinalNombres = Object.values(agrupadoNombres);
        setArticulosNombre(dataFinalNombres);
      })

      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposTelas = response.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);


  function PDFTabla() {
    let total_general = 0;
    let total_generalCosto = 0;

    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idTela"] + item["idColores"];
      groups[val] = groups[val] || {
        idTela: item.idTela,
        idColores: item.idColores,
        cantidad: 0,
        rollos: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idTela = item.idTela;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].costoTotal = item.costoTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].rollos += item.rollos;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      );
    }

    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idTela.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idTela.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "ancho" &&
      sorting.field != "peso" &&
      sorting.field != "costo" &&
      sorting.field != "costoTotal" &&
      sorting.field != "rollos" &&
      sorting.field != "codigo" &&
      sorting.field != "costoMt"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          total_generalCosto =
            parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
          return [
            a.codigo,
            a.nombre,
            a.colores,
            a.composicion,
            a.unidad,
            a.ancho,
            a.peso,
            a.tiposTelas,
            // "$" + new Intl.NumberFormat("en-US").format(a.costo),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.costoTotal),
          ];
        }
      );
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado InventariosTelas 333`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      columnStyles: {
        0: { minCellWidth: 20 },
        1: { minCellWidth: 20 },
        2: { minCellWidth: 18 },
        3: { minCellWidth: 10, fontSize: 8 },
        4: {
          minCellWidth: 20,
          // valign: 'center'
        },
        5: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        6: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        // 7:{ minCellWidth: 20,
        //   // valign: 'center'
        //  },
        8: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        9: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        10: {
          minCellWidth: 22,
          halign: "right",
          // valign: 'center'
        },
        11: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        12: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Color",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo de Tela",
          // "Costo",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      // styles: {overflow: 'linebreak'},
      // columnStyles: {
      // "Codigo": {minCellWidth: 50},
      // "Nombre": {minCellWidth: 50},
      // "Color": {minCellWidth: 50},
      // "Composicion": {minCellWidth: 50},
      // "Unidad": {minCellWidth: 50},
      // "Ancho": {minCellWidth: 50},
      // "Peso": {minCellWidth: 50},
      // "Tipo de Tela": {minCellWidth: 50},
      // "Venta": {minCellWidth: 50},
      //   Cantidad: {cellWidth: 300},
      //  },
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
          "$" + new Intl.NumberFormat("en-US").format(total_generalCosto),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`InventariosTelas.pdf`);
  }

  function excel() {
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idTela"] + item["idColores"];
      groups[val] = groups[val] || {
        idTela: item.idTela,
        idColores: item.idColores,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idTela = item.idTela;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].costoTotal = item.costoTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    const dataExcel = computedComments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      )
      .map((a) => {
        if (
          (selectedTiposTelasFiltro == 0 ||
            selectedTiposTelasFiltro == a.idTiposTelas) &&
          (selectedColor == 0 || selectedColor == a.idColores) &&
          (selectedCodigo == 0 || selectedCodigo == a.idTela) &&
          (selectedNombre == 0 || selectedNombre == a.idTela) &&
          (unidad == 0 || unidad == a.unidad) &&
          (selectedComposicion == 0 || selectedComposicion == a.composicion) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          return {
            Codigo: a.codigo,
            Nombre: a.nombre,
            Color: a.colores,
            Composicion: a.composicion,
            Unidad: a.unidad,
            Ancho: a.ancho,
            Peso: a.peso,
            Tipo: a.tiposTelas,
            Cantidad: a.cantidad,
            CostoTotal: a.costoTotal,
          };
        }
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventariosTelas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventariosTelas",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Color",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo",
          "Cantidad",
          "CostoTotal",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Color",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo",
          "Cantidad",
          "CostoTotal",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_general = 0;
    let total_generalCosto = 0;
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idTela"] + item["idColores"];
      groups[val] = groups[val] || {
        idTela: item.idTela,
        idColores: item.idColores,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idTela = item.idTela;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].costoTotal = item.costoTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    const data = computedComments
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      )
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
          (selectedTiposTelasFiltro == 0 ||
            selectedTiposTelasFiltro == a.idTiposTelas) &&
          (selectedColor == 0 || selectedColor == a.idColores) &&
          (selectedCodigo == 0 || selectedCodigo == a.idTela) &&
          (selectedNombre == 0 || selectedNombre == a.idTela) &&
          (unidad == 0 || unidad == a.unidad) &&
          (selectedComposicion == 0 || selectedComposicion == a.composicion) &&
          (selectedProveedor == 0 || selectedProveedor == a.idProveedor)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad);
          total_generalCosto =
            parseFloat(total_generalCosto) + parseFloat(a.costoTotal);
          return [
            a.codigo,
            a.nombre,
            a.colores,
            a.composicion,
            a.unidad,
            a.ancho,
            a.peso,
            a.tiposTelas,
            // "$" + new Intl.NumberFormat("en-US").format(a.costo),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.costoTotal),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado InventariosTelas 333`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      columnStyles: {
        0: { minCellWidth: 20 },
        1: { minCellWidth: 20 },
        2: { minCellWidth: 18 },
        3: { minCellWidth: 10, fontSize: 8 },
        4: {
          minCellWidth: 20,
          // valign: 'center'
        },
        5: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        6: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        // 7:{ minCellWidth: 20,
        //   // valign: 'center'
        //  },
        8: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        9: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        10: {
          minCellWidth: 22,
          halign: "right",
          // valign: 'center'
        },
        11: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
        12: {
          minCellWidth: 20,
          halign: "center",
          // valign: 'center'
        },
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Color",
          "Composicion",
          "Unidad",
          "Ancho",
          "Peso",
          "Tipo de Tela",
          // "Costo",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      // styles: {overflow: 'linebreak'},
      // columnStyles: {
      // "Codigo": {minCellWidth: 50},
      // "Nombre": {minCellWidth: 50},
      // "Color": {minCellWidth: 50},
      // "Composicion": {minCellWidth: 50},
      // "Unidad": {minCellWidth: 50},
      // "Ancho": {minCellWidth: 50},
      // "Peso": {minCellWidth: 50},
      // "Tipo de Tela": {minCellWidth: 50},
      // "Venta": {minCellWidth: 50},
      //   Cantidad: {cellWidth: 300},
      //  },
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_general),
          "$" + new Intl.NumberFormat("en-US").format(total_generalCosto),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado InventariosTelas",
          email: mailTo,
          fileName: "ListadoInventariosTelas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de InventariosTelas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function PDFTablaRollo() {
    let total_general = 0;
    const data = inventariosTelasRollos
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (a.idTela == articuloDesgloce && a.colores == colorDesgloce) {
          total_general = total_general + a.cantidad;
          return [
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "# " + a.idCompras,
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 159, 10, 31, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);

    doc.text(`Listado InventarioTelas por Rollo`, 22, 20);
    doc.setFontSize(12);
    doc.text(`Codigo: ${codigoDesgloce}`, 22, 30);
    doc.text(`Nombre: ${nombreDesgloce}`, 80, 30);
    doc.text(`Color: ${colorDesgloce}`, 22, 35);
    doc.text(`Unidad: ${unidadDesgloce}`, 80, 35);
    doc.text(`Tipo: ${tiposTelasDesgloce}`, 22, 45);
    doc.text(`Composicion: ${composicionDesgloce}`, 80, 40);
    doc.text(`Ancho: ${anchoDesgloce}`, 22, 50);
    doc.text(`Peso: ${pesoDesgloce}`, 80, 45);
    doc.autoTable({
      showHead: "firstPage",
      head: [["Cantidad", "Compra"]],
      body: dataPDFLimpia,
      startY: 55,
      foot: [[new Intl.NumberFormat("en-US").format(total_general)]],
    });
    doc.save(`InventariosTelas.pdf`);
  }

  function excelRollo() {
    const dataExcel = inventariosTelas.map((a) => {
      if (a.idTela == articuloDesgloce && a.colores == colorDesgloce) {
        return {
          Cantidad: a.cantidad,
          Compra: a.idCompras,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventariosTelas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventariosTelas",
        sheetFilter: ["Cantidad", "Compra"],
        sheetHeader: ["Cantidad", "Compra"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMailRollo() {
    let total_general = 0;
    const data = inventariosTelasRollos
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (a.idTela == articuloDesgloce && a.colores == colorDesgloce) {
          total_general = total_general + a.cantidad;
          return [
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "# " + a.idCompras,
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 159, 10, 31, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);

    doc.text(`Listado InventarioTelas por Rollo`, 22, 20);
    doc.setFontSize(12);
    doc.text(`Codigo: ${codigoDesgloce}`, 22, 30);
    doc.text(`Nombre: ${nombreDesgloce}`, 80, 30);
    doc.text(`Color: ${colorDesgloce}`, 22, 35);
    doc.text(`Unidad: ${unidadDesgloce}`, 80, 35);
    doc.text(`Tipo: ${tiposTelasDesgloce}`, 22, 45);
    doc.text(`Composicion: ${composicionDesgloce}`, 80, 40);
    doc.text(`Ancho: ${anchoDesgloce}`, 22, 50);
    doc.text(`Peso: ${pesoDesgloce}`, 80, 45);
    doc.autoTable({
      showHead: "firstPage",
      head: [["Cantidad", "Compra"]],
      body: dataPDFLimpia,
      startY: 55,
      foot: [[new Intl.NumberFormat("en-US").format(total_general)]],
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado InventariosTelas",
          email: mailTo,
          fileName: "ListadoInventariosTelas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de InventariosTelas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Color", field: "colores", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Ancho", field: "ancho", sortable: true },
    { name: "Peso", field: "peso", sortable: true },
    { name: "Tipo de Tela", field: "tiposTelas", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Rollos", field: "rollos", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Costo Mt", field: "costoMt", sortable: true },
    { name: "Costo Total", field: "costoTotal", sortable: true },
    { name: "Detalle", field: "detalle", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idTela"] + item["idColores"];
      groups[val] = groups[val] || {
        idTela: item.idTela,
        idColores: item.idColores,
        cantidad: 0,
        rollos: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val]._id = item._id;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].nombre = item.nombre;
      groups[val].idTela = item.idTela;
      groups[val].composicion = item.composicion;
      groups[val].descripcion = item.descripcion;
      groups[val].unidad = item.unidad;
      groups[val].ancho = item.ancho;
      groups[val].peso = item.peso;
      groups[val].rendimiento = item.rendimiento;
      groups[val].idTiposTelas = item.idTiposTelas;
      groups[val].tiposTelas = item.tiposTelas;
      groups[val].costo = item.costo;
      groups[val].costoTotal = item.costoTotal;
      groups[val].observaciones = item.observaciones;
      groups[val].colores = item.colores;
      groups[val].idColores = item.idColores;
      groups[val].rollos += item.rollos;
      groups[val].idProveedor = item.idProveedor;
      groups[val].proveedor = item.proveedor;
      return groups;
    }, []);

    let computedComments = Object.values(agrupado);

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.ancho.toString().includes(search) ||
          comment.peso.toString().includes(search) ||
          comment.costo.toString().includes(search) ||
          comment.costoTotal.toString().includes(search) ||
          comment.rollos.toString().includes(search) ||
          comment.codigo.toString().includes(search)
      );
    }

    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idTela.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idTela.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "ancho" &&
      sorting.field != "peso" &&
      sorting.field != "costo" &&
      sorting.field != "costoTotal" &&
      sorting.field != "rollos" &&
      sorting.field != "codigo" &&
      sorting.field != "costoMt"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "ancho" ||
        sorting.field == "peso" ||
        sorting.field != "costo" ||
        sorting.field != "costoTotal" ||
        sorting.field != "rollos" ||
        sorting.field != "codigo" ||
        sorting.field != "costoMt")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposTelasFiltro,
    selectedColor,
    selectedCodigo,
    selectedNombre,
    unidad,
    selectedComposicion,
    selectedProveedor,
  ]);

  function desgloce(
    idTela,
    color,
    nombre,
    codigo,
    peso,
    composicion,
    unidad,
    ancho,
    tiposTelas,
    idColores
  ) {
    setArticuloDesgloce(idTela);
    setColorDesgloce(color);
    setNombreDesgloce(nombre);
    setCodigoDesgloce(codigo);
    setPesoDesgloce(peso);
    setComposicionDesgloce(composicion);
    setUnidadDesgloce(unidad);
    setAnchoDesgloce(ancho);
    setTiposTelasDesgloce(tiposTelas);

    axios
      .get(`${URL_INVENTARIOS_TELAS}Tela/${idTela}/${idColores}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventariosTelas = response.data
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              cantidad: a.cantidad,
              codigo: a.telas[0].codigo,
              nombre: a.telas[0].nombre,
              idTela: a.telas[0]._id,
              composicion: a.telas[0].composicion,
              descripcion: a.telas[0].descripcion,
              unidad: a.telas[0].unidad,
              ancho: a.telas[0].ancho,
              peso: a.telas[0].peso,
              rendimiento: a.telas[0].rendimiento,
              idTiposTelas: a.telas[0].tiposTelas[0]._id,
              tiposTelas: a.telas[0].tiposTelas[0].name,
              costo: a.telas[0].costo,
              observaciones: a.telas[0].observaciones,
              colores: a.colores[0].name,
              idColores: a.colores[0]._id,
              idCompras: a.compras[0].idCompras,
              idProveedor: a.telasOC[0].ordenesCompra[0].proveedores[0]._id,
              proveedor:
                a.telasOC[0].ordenesCompra[0].proveedores[0]
                  .nombre_comercial,
              apartado:a.apartado
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(allInventariosTelas);
        setInventariosTelasRollos(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleDesgloce();
  }

  function clear() {
    setIdRolloEdit("");
    setArticuloEdit("");
    setUnidadEdit("");
    setCantidadEdit("");
    setCantidadAnteriorEdit("");
    setProduccionEdit("No");
    setApartadoEdit("No");
    setIdCompraEdit("");
    setNumeroCompraEdit("");
  }

  function editRollo() {
    const URL_INVENTARIOS_TELAS_EDIT = `${URL_INVENTARIOS_TELAS}/${idRolloEdit}`;
    const URL_COMPRA_EDIT = `${URL_COMPRAS}RolloEdit/${idCompraEdit}`;
        axios.patch(
          URL_INVENTARIOS_TELAS_EDIT,
          {
            cantidad: cantidadEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios
          .patch(
            `${URL_COMPRA_EDIT}`,
            {
              cantidadNuevo: cantidadEdit,
              cantidadAnterior: cantidadAnteriorEdit,
              unidadEdit: unidadEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Rollo",
                detalle: `Rollo ${idRolloEdit} / Cantidad Anterior ${cantidadAnteriorEdit} / Cantidad Nueva ${cantidadEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Actualizado con exito", "success");
            clear();
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function saveCortarRollo() {
    const URL_INVENTARIOS_TELAS_EDIT = `${URL_INVENTARIOS_TELAS}/${idRolloEdit}`;
        axios.patch(
          URL_INVENTARIOS_TELAS_EDIT,
          {
            cantidad: parseFloat(cantidadEdit) - parseFloat(cantidadCortar),
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios
          .post(
            `${URL_INVENTARIOS_TELAS}`,
            {
              cantidad: cantidadCortar,
              compras: rolloCortar.compras[0]._id,
              articulos: rolloCortar.telas[0]._id,
              telasOC: rolloCortar.telasOC[0]._id,
              colores: rolloCortar.colores[0]._id,
              captura: rolloCortar.captura + .1,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Cortar Rollo",
                detalle: `Rollo ${idRolloEdit} / Cantidad Anterior ${cantidadAnteriorEdit} / Cantidad Nueva ${cantidadEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{

              axios
              .get(`${URL_INVENTARIOS_TELAS}/${idRolloEdit}`, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              })
              .then((response) => {
                let allRollo = response.data;
                QRGen(allRollo._id, allRollo.telas[0].codigo, allRollo.telas[0].nombre, allRollo.colores[0].name, allRollo.cantidad, allRollo.telas[0].unidad )
              })
              .catch((err) => {
                console.log(err);
              });

              axios
              .get(`${URL_INVENTARIOS_TELAS}/${data.data._id}`, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              })
              .then((response) => {
                let allRollo = response.data;
                QRGen(allRollo._id, allRollo.telas[0].codigo, allRollo.telas[0].nombre, allRollo.colores[0].name, allRollo.cantidad, allRollo.telas[0].unidad )
              })
              .catch((err) => {
                console.log(err);
              });

              Swal.fire("Good job!", "Actualizado con exito", "success");
              clear();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function BuscaArticulo(event) {
    if (event.target.value.length >= 24) {
      axios
        .get(`${URL_INVENTARIOS_TELAS}/${event.target.value}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allRollo = response.data;
          setRolloCortar(allRollo)
          setIdRolloEdit(allRollo._id);
          setArticuloEdit(
            allRollo.telas[0].nombre + " / " + allRollo.colores[0].name
          );
          setUnidadEdit(allRollo.telas[0].unidad);
          setCantidadEdit(allRollo.cantidad);
          setCantidadAnteriorEdit(allRollo.cantidad);
          setProduccionEdit(allRollo.produccion);
          setApartadoEdit(allRollo.apartado);
          setIdCompraEdit(allRollo.compras[0]._id);
          setNumeroCompraEdit(allRollo.compras[0].idCompras);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIdRolloEdit(event.target.value);
    }
  }

  function BuscaArticuloHistorial(event) {
    if (event.target.value.length >= 24) {
      axios
        .get(`${URL_INVENTARIOS_TELAS}/${event.target.value}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allRolloHistorial = response.data;
          setIdRolloHistorial(allRolloHistorial._id);
          setArticuloHistorial(
            allRolloHistorial.telas[0].nombre +
              " / " +
              allRolloHistorial.colores[0].name
          );
          setUnidadHistorial(allRolloHistorial.telas[0].unidad);
          setCantidadHistorial(allRolloHistorial.cantidad);
          setCantidadAnteriorHistorial(allRolloHistorial.cantidad);
          setProduccionHistorial(allRolloHistorial.produccion);
          setIdCompraHistorial(allRolloHistorial.compras[0]._id);
          setNumeroCompraHistorial(allRolloHistorial.compras[0].idCompras);
          if (allRolloHistorial.produccion == "No") {
            setSurtidoHistorial("El Rollo no ha sido surtido");
          } else {
            setSurtidoHistorial(allRolloHistorial.surtidos[0].idSurtido);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIdRolloHistorial(event.target.value);
    }
  }

  function limpiarSearch() {
    setSearch("");
    setSelectedArticulo("");
    setSelectedCodigo("");
    setSelectedNombre("");
    setSelectedColor("");
    setSelectedComposicion("");
    setSelectedTiposTelasFiltro("");
    setSelectedProveedor("");
    setUnidad("");
  }

  let total = 0;
  let totalRollos = 0;
  let totalCosto = 0;

  function QRGen(id, codigo, nombre, color, cantidad, unidad) {
    const doc = new jsPDF("p", "mm", [102, 80]);
    let alturaLogo = 30;
    let alturaImagen = 60;
    let alturaTexto1 = 43;
    let alturaTexto2 = 48;
    let alturaTexto3 = 53;
    let alturaTexto4 = 58;
    let alturaTexto5 = 68;
    let alturaLogo2 = 35;

    let codigoQR = id;
    var base64 = qrcode.toDataURL(codigoQR);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 70, alturaLogo, 35, 10, null, null, 90);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 40, alturaLogo2, 60, 25, null, null, 90);
    var img3 = new Image();
    img3.src = base64;
    doc.addImage(img3, "png", 0, alturaImagen, 40, 40);

    doc.setFontSize(11);
    doc.text(`Cod ${codigo}`, alturaTexto1, 90, null, 90);
    doc.text(`Mod ${nombre}`, alturaTexto2, 90, null, 90);
    doc.text(`Color ${color}`, alturaTexto3, 90, null, 90);
    doc.text(`ID ${id}`, alturaTexto4, 90, null, 90);
    doc.setFontSize(18);
    doc.text(`Cant. ${cantidad} ${unidad}`, alturaTexto5, 90, null, 90);
    doc.save(`QR.pdf`);
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.inventariosTelas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuMateriales"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={toggleEditRollo}
              >
                Editar Articulo
              </Button>
              {/* <Button
                size="sm"
                className="btn"
                color="info"
                onClick={toggleHistorialRollo}
              >
                Historial Articulo
              </Button> */}

              <Button
                size="sm"
                className="btn"
                color="success"
                onClick={toggleCortarRollo}
              >
                Cortar Rollo
              </Button>

            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Inventarios Telas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size="sm" isOpen={modalMailRollo} toggle={toggleMailRollo}>
            <ModalHeader toggle={toggleMailRollo}>
              <h4>Enviar InventarioTelas por rollo</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMailRollo}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Inventario Telas</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>{" "}
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-md-1 flex-row-reverse">
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={limpiarSearch}
              >
                Limpiar
              </Button>
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosCodigos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idTela}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosNombre
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idTela}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColor}
                  onChange={(e) => {
                    setSelectedColor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedComposicion}
                  onChange={(e) => {
                    setSelectedComposicion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosComposicion
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a}>{a}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  placeholder="Unidad"
                  value={unidad}
                  required
                  onChange={(e) => {
                    setUnidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Metros">Metros</option>
                  <option value="Kilos">Kilos</option>
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedTiposTelasFiltro}
                  onChange={(e) => {
                    setSelectedTiposTelasFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {tipos_telas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                total = total + parseFloat(a.cantidad);
                totalRollos = totalRollos + parseFloat(a.rollos);
                totalCosto = totalCosto + parseFloat(a.costoTotal);

                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.colores}</td>
                      <td>{a.composicion}</td>
                      <td>{a.unidad}</td>
                      <td>{a.ancho}</td>
                      <td>{a.peso}</td>
                      <td>{a.tiposTelas} </td>
                      <td>{a.proveedor} </td>
                      {/* <td>
                        {"$" + new Intl.NumberFormat("en-US").format(a.costo)}
                      </td> */}
                      <td>{new Intl.NumberFormat("en-US").format(a.rollos)}</td>

                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            (
                              parseFloat(a.costoTotal) / parseFloat(a.cantidad)
                            ).toFixed(2)
                          )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(a.costoTotal)}
                      </td>
                      <td>
                        <Button
                          color="success"
                          id="Colores"
                          size="sm"
                          onClick={(e) =>
                            desgloce(
                              a.idTela,
                              a.colores,
                              a.nombre,
                              a.codigo,
                              a.peso,
                              a.composicion,
                              a.unidad,
                              a.ancho,
                              a.tiposTelas,
                              a.idColores
                            )
                          }
                        >
                          <i class="fas fa-bars"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalRollos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(totalCosto)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      <Modal size="lg" isOpen={modalDesgloce} toggle={toggleDesgloce}>
        <ModalHeader toggle={toggleDesgloce}>
          <h4>InventarioTelas del articulo</h4>
        </ModalHeader>
        <ModalBody>
          {/* <div className="card container"> */}
          <Row>
            <Col sm={12}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTablaRollo}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMailRollo}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelRollo}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label> Codigo: {codigoDesgloce} </Label>
            </Col>
            <Col md={4}>
              <Label> Nombre: {nombreDesgloce} </Label>
            </Col>
            <Col md={4}>
              <Label> Unidad: {unidadDesgloce} </Label>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label> Color: {colorDesgloce}</Label>
            </Col>
            <Col md={4}>
              <Label> Tipo de Tela: {tiposTelasDesgloce} </Label>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label> Comp: {composicionDesgloce} </Label>
            </Col>
            <Col md={4}>
              <Label> Ancho: {anchoDesgloce}</Label>
            </Col>
            <Col md={4}>
              <Label>Peso: {pesoDesgloce}</Label>
            </Col>
          </Row>
          <br />
          <br />
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>ID</th>
              <th>{unidadDesgloce}</th>
              <th>Compra</th>
              <th>QR</th>
            </tr>
            <tbody>
              {inventariosTelasRollos.map((a) => {
                return (
                  <tr>
                    <td>{a._id}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td># {a.idCompras}</td>
                    <td>
                      <Button
                        size="sm"
                        className="btn"
                        color="success"
                        onClick={(e) =>
                          QRGen(
                            a._id,
                            a.codigo,
                            a.nombre,
                            a.colores,
                            a.cantidad,
                            a.unidad
                          )
                        }
                      >
                        <i class="fas fa-qrcode"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* </div> */}
        </ModalBody>
      </Modal>

      {/* Modal Edit Rollo */}
      <Modal size="xl" isOpen={modalEditRollo} toggle={toggleEditRollo}>
        <ModalHeader toggle={toggleEditRollo}>
          <h4>Editar Rollo</h4>
        </ModalHeader>
        <ModalBody>
          {produccionEdit == "Si" || apartadoEdit == "Si"? (
            <Label className="mr-sm-2">
              El Rollo ya fue produccion o apartado, no se puede editar.
            </Label>
          ) : undefined}

          <br />

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Codigo</Label>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Compra</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Input
                bsSize="sm"
                name="idRolloEdit"
                type="string"
                value={idRolloEdit}
                onChange={(event) => {
                  BuscaArticulo(event);
                }}
              ></Input>
            </Col>
            <Col md={4}>
              <Input
                bsSize="sm"
                name="articuloEdit"
                type="string"
                value={articuloEdit}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="unidadEdit"
                type="string"
                value={unidadEdit}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="numeroCompraEdit"
                type="string"
                value={numeroCompraEdit}
                disabled
              ></Input>
            </Col>
            {produccionEdit == "No" || apartadoEdit == "Si" ? (
              <>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidadEdit"
                    type="string"
                    value={cantidadEdit}
                    onChange={(event) => {
                      setCantidadEdit(event.target.value);
                    }}
                  ></Input>
                </Col>

              </>
            ) : (
              <>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidadEdit"
                    type="string"
                    disabled
                    value={cantidadEdit}
                  ></Input>
                </Col>

              </>
            )}
          </Row>
          <br />
          <Row>
            <Col>
              {produccionEdit == "No" || apartadoEdit == "Si" ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={editRollo}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn btn-success"
                  disabled
                  tabindex="-1"
                >
                  Guardar
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Modal Historial Rollo */}
      <Modal
        size="xl"
        isOpen={modalHistorialRollo}
        toggle={toggleHistorialRollo}
      >
        <ModalHeader toggle={toggleHistorialRollo}>
          <h4>Historial de Rollo</h4>
        </ModalHeader>
        <ModalBody>
          <br />

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Codigo</Label>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Compra</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Input
                bsSize="sm"
                name="idRolloHistorial"
                type="string"
                value={idRolloHistorial}
                onChange={(event) => {
                  BuscaArticuloHistorial(event);
                }}
              ></Input>
            </Col>
            <Col md={4}>
              <Input
                bsSize="sm"
                name="articuloHistorial"
                type="string"
                value={articuloHistorial}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="unidadHistorial"
                type="string"
                value={unidadHistorial}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="numeroCompraHistorial"
                type="string"
                value={numeroCompraHistorial}
                disabled
              ></Input>
            </Col>

            <Col md={1}>
              <Input
                bsSize="sm"
                name="cantidadHistorial"
                type="string"
                disabled
                value={cantidadHistorial}
              ></Input>
            </Col>

          </Row>
          <br />
          <Row>
            <Col>
              <Label> Numero de Surtido {surtidoHistorial}</Label>
            </Col>
          </Row>
        </ModalBody>
      </Modal>


       {/* Modal Cortar Rollo */}
       <Modal size="xl" isOpen={modalCortarRollo} toggle={toggleCortarRollo}>
        <ModalHeader toggle={toggleCortarRollo}>
          <h4>Cortar Rollo</h4>
        </ModalHeader>
        <ModalBody>

        <Col md={3}>
              <Label className="mr-sm-2">Codigo</Label>
            </Col>

            <Col md={3}>
              <Input
                bsSize="sm"
                name="idRolloEdit"
                type="string"
                value={idRolloEdit}
                onChange={(event) => {
                  BuscaArticulo(event);
                }}
              ></Input>
            </Col>


          {produccionEdit == "Si" || apartadoEdit == "Si" ? (
            <>
          <br />
            <Label className="mr-sm-2">
              El Rollo ya esta en produccion, no se puede cortar.
            </Label>
            </>
          ) : (
<>
          <br />

          <Row>

            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Compra</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Cantidad a Cortar</Label>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Input
                bsSize="sm"
                name="articuloEdit"
                type="string"
                value={articuloEdit}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="unidadEdit"
                type="string"
                value={unidadEdit}
                disabled
              ></Input>
            </Col>
            <Col md={1}>
              <Input
                bsSize="sm"
                name="numeroCompraEdit"
                type="string"
                value={numeroCompraEdit}
                disabled
              ></Input>
            </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidadEdit"
                    type="string"
                    value={cantidadEdit}
                    disabled
                  ></Input>
                </Col>

                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="cantidadCortar"
                    type="string"
                    value={cantidadCortar}
                    onChange={(event) => {
                      if(event.target.value < cantidadEdit){
                      setCantidadCortar(event.target.value)
                    }else{
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "La cantidad a cortar no puede ser mayor o igual a la cantidad del rollo",
                      });
                      setCantidadCortar(0)
                      }
                    }}
                  ></Input>
                </Col>
          </Row>
          <br />
          <Row>
            <Col>
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={saveCortarRollo}
                >
                  Guardar
                </Button>
            </Col>
          </Row>
          </>
          )}
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoInventariosTelas;
