import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCorteCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ORDENES_CORTE = process.env.REACT_APP_URL_ORDENES_CORTE;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_ORDENES_CORTE =
    process.env.REACT_APP_URL_ARTICULOS_ORDENES_CORTE;
  const URL_ORDENES_CORTE_ACUMULADOS =
    process.env.REACT_APP_URL_ORDENES_CORTE_ACUMULADOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");

  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_piezas, setTotalPiezas] = useState(0);

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [fecha_pedido, setFechaPedido] = useState("");
  const [total_piezas_pedido, setTotalPiezasPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);

  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [articulosUnicos2, setArticulosUnicos2] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      cantidad: 0,
      idArticulosPedido: "",
      idArticulo: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_PEDIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveOrdenCorte = async (event) => {
    event.preventDefault();
    if (fecha != "") {
      setValidaBoton(false);
      toggleProgreso();

      // calculaOrdenCorte();
      let totalRollos = articulosPedido.length;
      try {
        await axios
          .post(
            URL_ORDENES_CORTE,
            {
              fecha,
              pedidos: selectedPedido,
              cantidad: total_piezas,
              observaciones,
              cortado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            articulosPedido.map((a) => {
              if (a.cantidad > 0) {
                axios
                  .post(
                    URL_ARTICULOS_ORDENES_CORTE,
                    {
                      ordenesCorte: data.data._id,
                      articulosPedido: a._id,
                      cantidad: parseFloat(a.cantidad),
                      pendiente_cortar: parseFloat(a.cantidad),
                      cortado: 0,
                      pedidos: selectedPedido,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Orden de Corte",
                            detalle: `${data.data._id}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", `success`);
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Orden de Corte",
                        detalle: `${data.data._id}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", `success`);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        cantidad: 0,
        idArticulosPedido: "",
        idArticulo: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalMts = values.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TM);
  };

  function BuscaArticulo(id, event) {



    articulosPedido.map((a) => {
      if (a.idArticulo == event.target.value) {
        let idArticulo = a.idArticulo;
        handleChangeInputArticulo(id, idArticulo);
      }
    });
  }

  const handleChangeInputArticulo = (id, idArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idArticulo = idArticulo;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function ActualizaTotales() {
    let totalMts = articulosPedido.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TM);
  }

  function jalaPedido(e) {
    setSelectedPedido(e);

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        let arrayTabla = allArticulosPedido.map((a) => {
          return {
            _id: a._id,
            idArticulo: a.articulos[0]._id,
            articuloNombre:
              a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " / " + a.articulos[0].nombre,
            talla: a.tallas[0].name,
            color: a.colores[0].name,
            cantidad: 0,
            cantidadPedido: a.cantidad,
            idArticuloHijo:""
          };
        });
        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].articuloNombre = item.articuloNombre;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicos(dataFinalAgru);

        let agrupado2 = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["color"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            color: item.color,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].color = item.color;
          groups[val].articuloNombre = item.articuloNombre;
          return groups;
        }, []);
        let dataFinalAgru2 = Object.values(agrupado2);
        setArticulosUnicos2(dataFinalAgru2);
      })
      .catch((err) => {
        console.log(err);
      });

    pedidos.map((a) => {
      if (a._id == e) {
        setFechaPedido(a.fecha);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalPiezasPedido(a.total_piezas);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.clientes[0].razon_social);
        setIdCliente(a.clientes[0]._id);
      }
    });
  }

  const handleChangeInputCantidad = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i._id) {
        i.cantidad = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);

    ActualizaTotales();
  };



  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Orden de Corte</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Pre Orden</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedPedido}
                  required
                  onChange={(e) => jalaPedido(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {pedidos
                    .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
                    .map((a) => {
                      if (
                        a.status == "Abierto" &&
                        a.is_active == "Si" &&
                        a.autorizado == "Si"
                      ) {
                        return (
                          <option value={a._id}>
                            # {a.idPedido} 
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
            {/* </Row>
            <Row> */}
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_piezas)}{" "}
                  pzas.
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}
            <br />
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idArticulo"
                      type="select"
                      value={inputField.idArticulo}
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) =>
                          a.articuloNombre > b.articuloNombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                              {a.articuloNombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>

                {articulosUnicos2.map((col) => {
                  if (col.idArticulo == inputField.idArticulo) {
                    return (
                      <>
                        <Row>
                          <Col md={9} className="tallas">
                            <Col md={1}>
                              <Label>{col.color}</Label>
                            </Col>
                            {articulosPedido.map((a) => {
                              if (col.color == a.color) {
                                return (
                                  <>
                                    <Col md={1}>
                                      <Label>
                                        {a.talla} / {a.cantidadPedido}
                                      </Label>
                                    </Col>
                                    <Col md={1}>
                                      <Input
                                        bsSize="sm"
                                        name="cantidad"
                                        type="number"
                                        value={a.cantidad}
                                        required
                                        onChange={(event) =>
                                          handleChangeInputCantidad(
                                            a._id,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          </Col>
                        </Row>
                      </>
                    );
                  }
                })}

                <br />
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_piezas)}{" "}
                  pzas
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}
          </Form>

          <br />
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={saveOrdenCorte}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn btn-success"
                  disabled
                  tabindex="-1"
                >
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoOrdenesCorte"
                className="btn btn-danger"
                id="botonListado"
                tabindex="-1"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCorteCreate;
