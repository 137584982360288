import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TablaCobranza from "./TablaCobranza";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoCobranza() {
  const { user } = useContext(AuthContext);
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_ABONOS_FACTURAS = process.env.REACT_APP_URL_ABONOS_FACTURAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [abonosFacturas, setAbonosFacturas] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [selectedCobrador, setSelectedCobrador] = useState("");
  const [facturas, setFacturas] = useState([]);
  const [pendiente, setPendiente] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const URL_CLIENTES = `${process.env.REACT_APP_URL_CLIENTES}`;
  const [cargosCliente, setCargosCliente] = useState([]);
  const [abonosCliente, setAbonosCliente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("0");
  const [selectedFechaFin, setSelectedFechaFin] = useState("0");
  const [selectedUltimoPagoInicio, setSelectedUltimoPagoInicio] = useState("0");
  const [selectedUltimoPagoFin, setSelectedUltimoPagoFin] = useState("0");

  useEffect(() => {
    axios
      .get(URL_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFacturas = response.data;
        setFacturas(allFacturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosFacturas = response.data;
        setAbonosFacturas(allAbonosFacturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCobradores = response.data;
        setCobradores(allCobradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Array Cargos
  const cargosFacturas = facturas
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        fecha: n.fecha,
        idCliente: n.clientes[0]._id,
        nombre: n.clientes[0].nombre,
        apellido: n.clientes[0].apellido,
        telefono: n.clientes[0].telefono,
        forma_pago: n.clientes[0].forma_pago,
        email: n.clientes[0].email,
        razon_social: n.clientes[0].razon_social,
        cargos: n.importe,
        abonos: 0,
        saldo: n.importe,
        cobrador:
          n.clientes[0].colaboradores[0].nombre +
          " " +
          n.clientes[0].colaboradores[0].apellido,
        idCobrador: n.clientes[0].colaboradores[0]._id,
        movimiento: "Cargo",
        fecha_visita: n.clientes[0].fecha_visita,
        ultimo_pago: n.clientes[0].ultimo_pago,
        direccion:
          n.clientes[0].calle +
          " " +
          n.clientes[0].numero_ext +
          " " +
          n.clientes[0].numero_int,
      };
    });

  //Array Abonos
  const abonosDon = abonosFacturas
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        fecha: n.fecha,
        idCliente: n.clientes[0]._id,
        nombre: n.clientes[0].nombre,
        apellido: n.clientes[0].apellido,
        telefono: n.clientes[0].telefono,
        forma_pago: n.clientes[0].forma_pago,
        email: n.clientes[0].email,
        razon_social: n.clientes[0].razon_social,
        cargos: 0,
        abonos: n.importe,
        saldo: -n.importe,
        cobrador:
          n.clientes[0].colaboradores[0].nombre +
          " " +
          n.clientes[0].colaboradores[0].apellido,
        idCobrador: n.clientes[0].colaboradores[0]._id,
        movimiento: "Abono",
        fecha_visita: n.clientes[0].fecha_visita,
        ultimo_pago: n.clientes[0].ultimo_pago,
        direccion:
          n.clientes[0].calle +
          " " +
          n.clientes[0].numero_ext +
          " " +
          n.clientes[0].numero_int,
      };
    });

  //Juntamos Arrays
  const arrayFacturas = cargosFacturas.concat(abonosDon);

  //   Agrupar abonos y cargos por cliente
  const groupByCliente = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        idCliente: item.idCliente,
        cargos: 0,
        abonos: 0,
        saldo: 0,
      };
      groups[val].cargos += item.cargos;
      groups[val].abonos += item.abonos;
      groups[val].saldo += item.saldo;
      groups[val].idCliente = item.idCliente;
      groups[val].nombre = item.nombre;
      groups[val].apellido = item.apellido;
      groups[val].telefono = item.telefono;
      groups[val].forma_pago = item.forma_pago;
      groups[val].email = item.email;
      groups[val].razon_social = item.razon_social;
      groups[val].cobrador = item.cobrador;
      groups[val].idCobrador = item.idCobrador;
      groups[val].fecha_visita = item.fecha_visita;
      groups[val].ultimo_pago = item.ultimo_pago;
      groups[val].direccion = item.direccion;
      return groups;
    }, []);
  };

  const facturasAgrupado = groupByCliente(arrayFacturas, "idCliente");
  const dataFinalFacturas = Object.values(facturasAgrupado).sort((a, b) =>
    a.fecha_visita > b.fecha_visita ? 1 : -1
  );

  // PDF
  let totalCargos = 0;
  let totalAbonos = 0;
  let totalSaldo = 0;

  let tablaCargos = 0;
  let tablaAbonos = 0;
  let tablaSaldo = 0;

  function PDFTabla() {
    const data = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente > a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.nombre + " " + a.apellido,
          a.direccion,
          a.telefono,
          a.cobrador,
          a.ultimo_pago,
          a.fecha_visita,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Cobranza`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Direccion",
          "Telefono",
          "Cobrador",
          "Ult. Pago",
          "Visita",
          "Saldo",
          "Pago",
          "Sig Visita",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Cobranza.pdf`);
  }
  function excel() {
    const dataExcel = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente > a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        return {
          Nombre: a.nombre + " " + a.apellido,
          Direccion: a.direccion,
          Telefono: a.telefono,
          Cobrador: a.cobrador,
          UltimoPago: a.ultimo_pago,
          ProximaVisita: a.fecha_visita,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCobranza";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCobranza",
        sheetFilter: [
          "Nombre",
          "Direccion",
          "Telefono",
          "Cobrador",
          "UltimoPago",
          "ProximaVisita",
          "Saldo",
        ],
        sheetHeader: [
          "Nombre",
          "Direccion",
          "Telefono",
          "Cobrador",
          "UltimoPago",
          "ProximaVisita",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = dataFinalFacturas.map((a) => {
      if (
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (pendiente == 0 || (pendiente > a.saldo && a.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 ||
          selectedUltimoPagoFin >= a.ultimo_pago) &&
        (value == null || value._id == a.idCliente)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.nombre + " " + a.apellido,
          a.direccion,
          a.telefono,
          a.cobrador,
          a.ultimo_pago,
          a.fecha_visita,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Cobranza`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Direccion",
          "Telefono",
          "Cobrador",
          "Ult. Pago",
          "Visita",
          "Saldo",
          "Pago",
          "Sig Visita",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Cobranza",
          email: mailTo,
          fileName: "Resumen-Cobranza.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el resumen de cobranza.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const options = clientes.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_facturas ? (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuClientes"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Resumen de Cargos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>

                <h3 align="center">Listado Cobranza</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th style={{ paddingBottom: "0px" }}>Cliente</th>
                      <th style={{ paddingBottom: "0px", textAlign: "center" }}>
                        Ultimo Pago
                      </th>
                      <th style={{ paddingBottom: "0px", textAlign: "center" }}>
                        Proxima Visita
                      </th>
                      <th style={{ paddingBottom: "0px" }}>Cobrador</th>
                      <th style={{ paddingBottom: "0px" }}>Saldo</th>
                      <th style={{ paddingBottom: "0px" }}>Forma Pago</th>
                      <th style={{ paddingBottom: "0px" }}>Detalle</th>
                      <th style={{ paddingBottom: "0px" }}>Contacto</th>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedCliente) => {
                            setValue(selectedCliente);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        />
                      </td>

                      <td style={{ paddingTop: "0px" }}>
                        <Col md={12}>
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoInicio}
                            required
                            onChange={(e) => {
                              setSelectedUltimoPagoInicio(e.target.value);
                            }}
                          />
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoFin}
                            required
                            onChange={(e) => {
                              setSelectedUltimoPagoFin(e.target.value);
                            }}
                          />
                        </Col>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaInicio}
                          required
                          onChange={(e) => {
                            setSelectedFechaInicio(e.target.value);
                          }}
                        />
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaFin}
                          required
                          onChange={(e) => {
                            setSelectedFechaFin(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCobrador}
                          required
                          onChange={(e) => setSelectedCobrador(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {cobradores
                            .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                            .map((a) => {
                              if (a.cobrador == "Si") {
                                return (
                                  <option value={a._id}>
                                    {a.nombre} {a.apellido}
                                  </option>
                                );
                              }
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={pendiente}
                          required
                          onChange={(e) => setPendiente(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          <option value=".000000001">Solo Pendientes</option>
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                      bsSize="sm"
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFinalFacturas.map((c) => {
                      if (
                        (selectedCobrador == 0 ||
                          selectedCobrador == c.idCobrador) &&
                        (pendiente == 0 ||
                          (pendiente > c.saldo && c.saldo != 0)) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= c.fecha_visita) &&
                        (selectedFechaFin == 0 ||
                          selectedFechaFin >= c.fecha_visita) &&
                        (selectedUltimoPagoInicio == 0 ||
                          selectedUltimoPagoInicio <= c.ultimo_pago) &&
                        (forma_pago == 0 ||
                          forma_pago == c.forma_pago)&&
                          (selectedUltimoPagoFin == 0 ||
                            selectedUltimoPagoFin >= c.ultimo_pago) &&
                        (value == null || value._id == c.idCliente)
                      ) {
                        tablaCargos = tablaCargos + c.cargos;
                        tablaAbonos = tablaAbonos + c.abonos;
                        tablaSaldo = tablaSaldo + c.saldo;
                        return (
                          <tr>
                            <TablaCobranza
                              nombre={c.nombre}
                              apellido={c.apellido}
                              email={c.email}
                              razon_social={c.razon_social}
                              cargos={c.cargos}
                              abonos={c.abonos}
                              saldo={c.saldo}
                              idCliente={c.idCliente}
                              cobrador={c.cobrador}
                              ultimo_pago={c.ultimo_pago}
                              fecha_visita={c.fecha_visita}
                              direccion={c.direccion}
                              telefono={c.telefono}
                              forma_pago={c.forma_pago}
                            />
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaSaldo)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoCobranza;
