import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PADRE = process.env.REACT_APP_URL_ARTICULOS_PADRE;

  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");  
  const [articulosPadre, setArticulosPadre] = useState([]);
  const [selectedArticulosPadre, setSelectedArticulosPadre] = useState("");

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS_PADRE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPadre = res.data;
        setArticulosPadre(allArticulosPadre);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

console.log("hola", selectedArticulosPadre)
  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            nombre,
            descripcion,
            observaciones,
            articulosPadre: selectedArticulosPadre,
            catalogoProcesos: "63f64e9f8b18200dea7fadb0",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then((data) => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Modelo",
              detalle: `${nombre}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.articulos_create ? (
        <div className="card container">
          <h3 align="center">Nuevo Modelo</h3>
          <Form onSubmit={saveArticulos}>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Articulo Padre</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArticulosPadre}
                  required
                  onChange={(e) => {
                    setSelectedArticulosPadre(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {articulosPadre
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo} {a.nombre}</option>;
                    })}
                </Input>
              </Col>             
              <Col md={3}>
                <Label>Prenda</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
