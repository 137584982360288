import React, { useState, useEffect, useContext, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import { Table, Button, Col, Row, Label, Input, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import DetalleFacturaInfo from "./DetalleFacturaInfo";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoDetalleFacturas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  
  const [facturas, setFacturas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [value, setValue] = useState();
  const [valueEdit, setValueEdit] = useState();

  const [text, setText] = useState(false);

  const [selectedClienteEdit, setSelectedClienteEdit] = useState('')
  const [fecha, setFecha] = useState('')
  const [importe, setImporte] = useState('')
  const [importeViejo, setImporteViejo] = useState('')
  const [saldo, setSaldo] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [nota, setNota] = useState("");
  const [IdEditFactura, setIdEditFactura] = useState('')
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

const [activo, setActivo] = useState("");

const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_FACTURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFacturas = response.data;
        setFacturas(allFacturas);
        let arrayTabla = allFacturas
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            nota:a.nota,
            fecha:a.fecha,
            cliente:a.clientes[0].nombre + " " + a.clientes[0].apellido,
            idClientes:a.clientes[0]._id,
            telefono:a.clientes[0].telefono,
            email:a.clientes[0].email,
            cobrador:a.clientes[0].colaboradores[0].nombre + " " + a.clientes[0].colaboradores[0].apellido,
            idCobrador:a.clientes[0].colaboradores[0]._id,
            importe:a.importe,
            saldo:a.saldo,
            descripcion:a.descripcion,
            abonos:a.importe-a.saldo
          };
        }).filter(function (el) {
          return el != null;
        })
      let dataFinal = Object.values(arrayTabla);
      setComments(dataFinal)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let total = 0;
  let totalSaldo = 0;
  let totalAbonos = 0;

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = facturas.map((c) => {
      if (
        (value == null || value._id == c.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.clientes[0].colaboradores[0]._id)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          [c.clientes[0].nombre + " " + c.clientes[0].apellido],
          [c.clientes[0].colaboradores[0].nombre + " " + c.clientes[0].colaboradores[0].apellido],
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text("Detalle de Notas", 20, 40);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Cliente",
          "Cobrador",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleNotas.pdf");
  }

  function excel(){
    const dataExcel = facturas.map((c) => {
      if (
        (value == null || value._id == c.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.clientes[0].colaboradores[0]._id)
      ) {
        return {
          Fecha: c.fecha,
          Cliente: c.clientes[0].nombre + " " + c.clientes[0].apellido,
          Cobrador: c.clientes[0].colaboradores[0].nombre + " " + c.clientes[0].colaboradores[0].apellido,
          Importe: c.importe}
        ;
      }
    });

    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });


    var option = {};
 
option.fileName = "DetalleNotas";
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: "DetalleNotas",
    sheetFilter: [ "Fecha",
    "Cliente",
    "Cobrador",
    "Importe"],
    sheetHeader: [
      "Fecha",
      "Cliente",
      "Cobrador",
      "Importe"
    ],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  function enviaMail(){
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = facturas.map((c) => {
      if (
        (value == null || value._id == c.clientes[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.clientes[0].colaboradores[0]._id)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          [c.clientes[0].nombre + " " + c.clientes[0].apellido],
          [c.clientes[0].colaboradores[0].nombre + " " + c.clientes[0].colaboradores[0].apellido],
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Notas", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Cliente",
          "Cobrador",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Detalle Notas',
         email: mailTo,
         fileName: 'Detalle-Notas.pdf',
         att: base64File,
         text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el detalle de notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
setTimeout(() => {
      window.location.reload();
    }, 1000);
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }
 
  let cantidad = 0

  function editFactura(event) {
    event.preventDefault();
    let saldoNuevo = importe - importeViejo + saldo
    const URL_FACTURAS_EDIT = `${process.env.REACT_APP_URL_FACTURAS}/${IdEditFactura}`;
    axios
      .patch(
        URL_FACTURAS_EDIT,
        {
          fecha,
          descripcion,
          importe,
          nota,
          saldo: saldoNuevo
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Nota',
            detalle: `Numero: ${nota}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit) {
    const URL_FACTURAS_EDIT = `${process.env.REACT_APP_URL_FACTURAS}/${idEdit}`;
    axios
      .get(URL_FACTURAS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setFecha(response.data.fecha);
        setNota(response.data.nota);
        setDescripcion(response.data.descripcion);
        setImporteViejo(response.data.importe);
        setImporte(response.data.importe);
        setIdEditFactura(idEdit)
        setSaldo(response.data.saldo)
        toggleEdit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const options = clientes.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  // const optionsEdit = clientes.map((option) => {
  //   const juntaEdit =
  //     option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
  //   const firstLetterEdit = option.apellido[0].toUpperCase();
  //   return {
  //     firstLetterEdit: /[0-9]/.test(firstLetterEdit) ? "0-9" : firstLetterEdit,
  //     ...option,
  //     juntaEdit,
  //   };
  // });

  const headers = [
    { name: "Nota", field: "nota", sortable: false },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "Cliente", sortable: true },
    { name: "Cobrador", field: "cobrador", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Abonos", field: "abonos", sortable: false },
    { name: "Saldo", field: "saldo", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments

  if (search) {
      computedComments = computedComments.filter(
          comment =>
              comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
              comment.cobrador.toLowerCase().includes(search.toLowerCase()) 
      );
  }

  setTotalItems(computedComments.length);

  //Sorting comments
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  if(selectedFechaInicio){
    computedComments = computedComments.filter((e)=>
    e.fecha >= selectedFechaInicio
    ) 
  }

  if(selectedFechaFin){
    computedComments = computedComments.filter((e)=>
    e.fecha <= selectedFechaFin
    ) 
  }

  if(selectedColaborador){
    computedComments = computedComments.filter((e)=>
    e.idCobrador.includes(selectedColaborador)
    ) 
  } 

  if(value){
    computedComments = computedComments.filter((e)=>
    e.idClientes.includes(value._id)
    ) 
  } 

  //Current Page slice
  return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [comments, currentPage, search, sorting, selectedFechaInicio, selectedFechaFin, selectedColaborador, value]);


  return (
    <>
      <Header />
      <br />
      <br />
        {user.menu_facturas ?(
            <div className="card container">
                  <Row>
                    <Col sm={8}>
                    {user.facturas_cargos_create ?(
                      <Button
                        size="sm"
                        href="/NotasCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Nota
                      </Button>
                    ):(
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Nota
                      </Button>
                    )}
                      <Button
                        size="sm"
                        href="/MenuClientes"
                        className="btn btn-danger"
                        id="botonListado"
                      >
                        Regresar
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <ButtonGroup id="logoutBoton">
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTabla}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button 
                        size="sm" 
                        className="btn" 
                        color="info"
                        onClick={toggleMail}
                        >
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Detalle Notas</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>

                  <h3 align="center">Notas</h3>

                  <Row>
                    <Col md={2}>
                      <Label className="mr-sm-2">Fecha Inicio</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaInicio}
                        required
                        onChange={(e) => {
                          setSelectedFechaInicio(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">Fecha Fin</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaFin}
                        required
                        onChange={(e) => {
                          setSelectedFechaFin(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>

                  <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                <tr>
                <td></td>
                <td></td>
                        <td style={{ paddingTop: "0px" }}>
                        <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedCliente) => {
                            setValue(selectedCliente)
                            setCurrentPage(1);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        />
                      </td>

                      
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                required
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                  setCurrentPage(1)
                  console.log(e.target.value)
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if (a.cobrador=="Si") {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    }
                  })}
              </Input>
                        </td>
                      </tr>
                    <tbody>
                      {commentsData
                        .map((c) => { {
                            total = total + c.importe;
                            totalSaldo = totalSaldo + c.saldo;
                            totalAbonos = totalAbonos + c.abonos;
                            cantidad = cantidad + 1
                            return (
                              <tr>
                              <td>{c.nota}</td>
                              <td>{c.fecha}</td>
                              <td>{c.cliente}</td>
                              <td>{c.cobrador}</td>
                              <td >
                                  {"$" + new Intl.NumberFormat("en-US").format(c.importe)}
                                </td>
                                <td >
                                  {"$" + new Intl.NumberFormat("en-US").format(c.abonos)}
                                </td>
                                <td >
                                  {"$" + new Intl.NumberFormat("en-US").format(c.saldo)}
                                </td>
                                <td> 
                                  <Row>
                                  <DetalleFacturaInfo 
                                  nombre={c.cliente} 
                                  telefono={c.telefono}
                                  email={c.email}
                                  importe={"$" + new Intl.NumberFormat("en-US").format(c.importe)}
                                  descripcion={c.descripcion}
                                  nota={c.nota}
                                />
                                {user.facturas_cargos_create ?(
                                   <Button
                                   color="info"
                                   id="Editar"
                                   size="sm"
                                   onClick={(e) => jalaInfo(c._id)}
                                 ><i class="far fa-edit"></i>
                                 </Button>
                                    ):(
                                      <Button color="info" id="Editar" size="sm" disabled>
                                      <i class="far fa-edit"></i>
                                    </Button>
                                    )}
                                    </Row>
                                  </td>
                              </tr>
                            );
                          }
                        })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          TOTAL
                        </td>
                        <td className="negrita">
                          {"$" + new Intl.NumberFormat("en-US").format(total)}
                        </td>
                        <td className="negrita">
                        {"$" + new Intl.NumberFormat("en-US").format(totalAbonos)}
                        </td>
                        <td className="negrita">
                        {"$" + new Intl.NumberFormat("en-US").format(totalSaldo)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
          ): undefined } 
       <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Nota</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {/*   <Col>
              <Label className="mr-sm-2">Cliente</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedClienteEdit}
              required
              onChange={(e) => {
                setSelectedClienteEdit(e.target.value);
              }}
            >
              <option value="0">Selecciona un Cliente</option>
              {clientes
              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
              .map((c) => {
                return <option value={c._id}>{c.nombre} {c.apellido}</option>;
              })}
            </Input> */}
            {/* <Autocomplete
                          size="small"
                          value={valueEdit}
                          onChange={(event, selectedClienteEdit) => {
                            setValueEdit(selectedClienteEdit);
                          }}
                          optionsEdit={optionsEdit.sort(
                            (a, b) =>
                              -b.firstLetterEdit.localeCompare(a.firstLetterEdit)
                          )}
                          groupBy={(option) => option.firstLetterEdit}
                          getOptionLabel={(option) => option.juntaEdit}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        /> 
              </Col>*/}
              <Col md={6}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
            <Col md={6}>
                <Label>Importe</Label>
                <Input
                  className="col-sm-12"
                  type="number"
                  placeholder="Importe"
                  value={importe}
                  required
                  onChange={(e) => {
                    setImporte(e.target.value);
                  }}
                />
              </Col>
              </Row>
           
            <Row>
           
              <Col md={6}>
                  <Label>Nota</Label>
                  <Input
                    type="text"
                    value={nota}
                    required
                    onChange={(e) => {
                      setNota(e.target.value);
                    }}
                  />
                </Col>
              <Col md={6}>
            <Label className="mr-sm-2">Descripcion</Label>
            <Input
              className="col-sm-12"
              type="textarea"
              placeholder="Descripcion"
              value={descripcion}
              required
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
           </Col>
           </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editFactura}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoDetalleFacturas;
