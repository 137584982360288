import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosEntradaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_TRASPASOS_ENTRADA = process.env.REACT_APP_URL_TRASPASOS_ENTRADA;
  const URL_ARTICULOS_TE = process.env.REACT_APP_URL_ARTICULOS_TE;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaSalida, setAreaSalida] = useState("");
  const [areaDestino, setAreaDestino] = useState(user.areas);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [traspasosSalida, setTraspasosSalida] = useState([]);
  const [selectedTraspasoSalida, setSelectedTraspasoSalida] = useState("");

  
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();


  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTraspasosEntrada = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalArticulos = inventarios.length + 1
    try {
      await axios
        .post(
          URL_TRASPASOS_ENTRADA,
          {
            fecha,
            traspasosSalida:selectedTraspasoSalida,
            observaciones,
            user: user.id,
            total
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inventarios.map((a) => {
            axios.post(
              URL_ARTICULOS_TE,
              {
                traspasosEntrada: data.data._id,
                articulos: a.idArticulo,
                cantidad: parseFloat(a.cantidad),
                areaDestino: a.areaDestino,
                tallas: a.idTalla,
                colores: a.idColor
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              totalArticulos = totalArticulos -1
              if(totalArticulos == 0){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Traspaso Entrada",
                  detalle: `${areaDestino} ${total}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
              }
            })
          })

          // Actualizamos Traspasos Salida
            axios.patch(
              `${URL_TRASPASOS_SALIDA}/${selectedTraspasoSalida}`,
              {
                recibida: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ).then(()=>{
              totalArticulos = totalArticulos -1
              if(totalArticulos == 0){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Traspaso Entrada",
                  detalle: `${areaDestino} ${total}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
              }
            })

        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
 

  function jalaAlmacen(e) {
    setAreaDestino(e);

    axios
    .get(`${URL_TRASPASOS_SALIDA}/almacen/${e}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allTraspasosSalida = res.data;
      setTraspasosSalida(allTraspasosSalida);
    })
    .catch((err) => {
      console.log(err);
    });

    setInventarios([])

  }

  function jalaArticulosSalida(e) {
    setSelectedTraspasoSalida(e);

    axios
      .get(`${URL_ARTICULOS_TS}/traspasoSalida/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        let arrayTabla = allInventarios
        .map((a) => {
          return {
            _id: a._id,
            articulo: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " / " + a.articulos[0].nombre + " / " + a.colores[0].name + " / " + a.tallas[0].name,
            idTalla: a.tallas[0]._id,
            idColor: a.colores[0]._id,
            idArticulo: a.articulos[0]._id,
            cantidad: a.cantidad,
            areaDestino: a.traspasosSalida[0].areaDestino[0]._id,
            traspasosSalida: a.traspasosSalida[0]._id,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      let dataFinal = Object.values(arrayTabla);


        setInventarios(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotal(TC);
      })
      .catch((err) => {
        console.log(err);
      })

  }

  const handleChangeInput = (id, event) => {
    const newInventarios = inventarios.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInventarios(newInventarios);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_entrada_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Entrada</h3>
          <Form onSubmit={saveTraspasosEntrada}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                { user.areas == AREAS_GENERAL ? (
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    jalaAlmacen(e.target.value);
                  }}
                >
                <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a._id != AREAS_GENERAL){
                      return <option value={a._id}>{a.name}</option>
                      }})}
                </Input>
                ):(
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                 onChange={(e) => {
                  jalaAlmacen(e.target.value);
                }}
                >
                <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (user.areas == a._id) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>)}
                
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Salida</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedTraspasoSalida}
                  required
                  onChange={(e) => {
                    jalaArticulosSalida(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Salida</option>
                  {traspasosSalida
                    .sort((a, b) => (a.idTraspasosSalida < b.idTraspasosSalida ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.idTraspasosSalida} / {a.areaSalida[0].name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

              <Row> 
            
                <Col md={3}>
                  <Label className="mr-sm-2">Modelo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                
              </Row>

              {inventarios.map((inventario) => (
                <div key={inventario.id}>
                  <Row> 
                   
                  <Col md={3}>
                      <Input
                        bsSize="sm"
                        name="articulo"
                        type="text"
                        placeholder="Cantidad"
                        value={inventario.articulo}
                        required
                        disabled
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Cantidad"
                        value={inventario.cantidad}
                        required
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosEntrada"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosEntradaCreate;
