import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext"
import { Badge, Table, Row, Col, ButtonGroup, Button, Label, Input } from "reactstrap";
import axios from 'axios'
import TablaFacturasInfo from './TablaFacturasInfo'
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import encode from "nodejs-base64-encode";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function FacturasInfo(props) {
    const { user } = useContext(AuthContext);
    const URL_LOGS = process.env.REACT_APP_URL_LOGS;
    const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
    const URL_FACTURAS_CLIENTE = `${process.env.REACT_APP_URL_FACTURAS}/cliente/${props.idCliente}`;
    const [cargos, setCargos] = useState([]);
    const URL_ABONOS_FACTURAS_CLIENTE = `${process.env.REACT_APP_URL_ABONOS_FACTURAS}/cliente/${props.idCliente}`;
    const [abonos, setAbonos] = useState([]);
    const URL_CLIENTES = `${process.env.REACT_APP_URL_CLIENTES}/${props.idCliente}`;
    const [direccion, setDireccion] = useState('')
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')

    const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  
    useEffect(() => {
      axios
        .get(URL_FACTURAS_CLIENTE, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allCargos = response.data;
          setCargos(allCargos);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(URL_ABONOS_FACTURAS_CLIENTE, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allAbonos = response.data;
          setAbonos(allAbonos);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
        .get(URL_CLIENTES, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allDir = response.data.calle +" # "+ response.data.numero_ext + " Int "+ response.data.numero_int + " Col "+response.data.colonia
          setDireccion(allDir);
          setTelefono(response.data.telefono) 
          setEmail(response.data.email)
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    let importesCargos = cargos.map((c)=> c.importe)
    let totalCargos = importesCargos.reduce((total, importe, index) => total + importe, 0);

    let importesAbonos = abonos.map((a)=> a.importe)
    let totalAbonos = importesAbonos.reduce((total, importe, index) => total + importe, 0);

    let saldo = totalCargos - totalAbonos

    const arrayCargos = 
      cargos.map((c)=>{
      return(
        {fecha: c.fecha, movimiento: "Nota", cargos:c.importe, abonos:"", forma_pago:"NA"})
    })
    
    const arrayAbonos = 
    abonos.map((n)=>{
    return(
      {fecha: n.fecha, movimiento: "Abono", cargos:"", abonos:n.importe, idAbonoFactura:n.idAbonoFactura, forma_pago:n.forma_pago})
  })

  const arrayMovimientos = arrayCargos.concat(arrayAbonos).sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
  var saldoParcial = 0
  var saldoParcialTabla = 0
  
  function PDFTabla() {
    const data = arrayMovimientos
      .map((a) => {
      saldoParcial = saldoParcial + a.cargos - a.abonos
      const cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
      const abonos = "$" + new Intl.NumberFormat("en-US").format(a.abonos);
      var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha)
        ) {
      return [a.fecha, a.movimiento, a.forma_pago, cargos, abonos, saldoParcialFormato];
        } 
        // else return ["Saldo", "Anterior", " ", " ", " ", " ", " ", saldoParcialFormato]
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);
    doc.text(`Estado de Cuenta ${props.nombre} ${props.apellido}`,22,35);
    doc.setFontSize(10)
    doc.text(`${direccion}`,22,40);
    doc.text(`${telefono} ${email}`,22,44);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Forma Pago", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "TOTALES:",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(saldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaCliente-${props.nombre}${props.apellido}.pdf`);
  }

  function excel(){
    const dataExcel = arrayMovimientos.map((a) => {
      return {Fecha:a.fecha, Movimiento:a.movimiento, Forma:a.forma_pago, Importe:a.importe};
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = `EdoCtaCliente`;
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: `EdoCtaCliente`,
    sheetFilter: ["Fecha", "Concepto", "Forma", "Importe"],
    sheetHeader: ["Fecha", "Concepto", "Forma", "Importe"],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  function sendMail() {
    const data = arrayMovimientos
      .map((a) => {
        saldoParcial = saldoParcial + a.cargos - a.abonos
        const cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abonos = "$" + new Intl.NumberFormat("en-US").format(a.abonos);
        var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
        if (
          (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha)
          ) {
        return [a.fecha, a.movimiento, a.forma_pago, cargos, abonos, saldoParcialFormato];
          } 
          // else return ["Saldo", "Anterior", " ", " ", " ", " ", " ", saldoParcialFormato]
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 140, 20, 60, 25);
      doc.text(`Estado de Cuenta ${props.nombre} ${props.apellido}`,22,35);
      doc.setFontSize(10)
      doc.text(`${direccion}`,22,40);
      doc.text(`${telefono} ${email}`,22,44);
      doc.autoTable({
        head: [["Fecha", "Movimiento", "Forma Pago", "Cargos", "Abonos", "Saldo"]],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "TOTALES:",
            "$" + new Intl.NumberFormat("en-US").format(totalCargos),
            "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
            "$" + new Intl.NumberFormat("en-US").format(saldo),
          ],
        ],
        showFoot: "lastPage",
    });
   
    var att = doc.output('arraybuffer');
  
    var base64File = encode.encode(att, 'base64')
  
    // Envia el Mail
    axios
    .post(
      URL_SEND_MAIL_ATT,
      {
        subject:'Estado de Cuenta',
        email: props.email,
        fileName: 'EdoCta.pdf',
        att: base64File,
        text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
        <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
        <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
        <tbody>
        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola ${props.nombre + " " + props.apellido}.</tr>
        <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras tu estado de cuenta.</tr>
        </tbody>
        <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
        <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
        </table>`
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      Swal.fire("Good job!", "Enviado con exito", "success");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    })
  }

  let tablaSaldo = 0


  function PDFRecibo(idAbonoFactura, fecha, nombre, apellido, importe) {
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img, "png", 150, 10, 35, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img2, "png", 140, 20, 60, 25);
                                  // Horizontal, Vertical
    doc.setFontSize(16)
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`,65,65);
    doc.text(`__________________`,80,100);
    doc.text(`Firma`,100,110);
    doc.autoTable({
      head: [["No Recibo","Fecha", "Cliente", "Importe"]],
      body: [[idAbonoFactura, fecha, [nombre + " " + apellido], "$" + new Intl.NumberFormat("en-US").format(importe)]],
      startY: 70,
    });
    doc.save(`Recibo-${idAbonoFactura}.pdf`);
  }

    return (
        <>
        <div className="card container">
          <Row>
          <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
          </Row>
        <Row>
          <Col sm={5}>
            <ButtonGroup>
              <Button size="sm" className="btn" color="danger" onClick={PDFTabla}>
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              <Button size="sm" className="btn" color="info" onClick={sendMail}>
                eMail <i class="fas fa-at"></i>
              </Button>
              <Button
                size="sm"
                className="btn"
                color="primary"
                onClick={excel}
              >
                Excel <i class="far fa-file-excel"></i>
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm={7}>
            <h4 align="right">
              Saldo{" "}
              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                {"$" + new Intl.NumberFormat("en-US").format(saldo)}
              </Badge>
            </h4>
          </Col>
        </Row>
            <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                      <tr align="center">
                        <th className="tituloTabla" align="left">
                          Fecha
                        </th>
                        <th className="tituloTabla">
                          Movimiento
                        </th>
                        <th>
                          Forma de Pago
                        </th>
                        <th className="tituloTabla">
                          Cargos
                        </th><th className="tituloTabla">
                          Abonos
                        </th>
                        <th className="tituloTabla">
                          Saldo
                        </th>
                        <th className="tituloTabla">
                          Recibo
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {arrayMovimientos
                      .map((c) => {
                        saldoParcialTabla = saldoParcialTabla + c.cargos - c.abonos
                        if (
                          (selectedFechaInicio == 0 ||
                            selectedFechaInicio <= c.fecha)
                        ) {
                        return (
                          <tr>
                            <td align="center">{c.fecha}</td>
                           <td align="center">{c.movimiento}</td>
                           <td col="col-sm-2">{c.forma_pago}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.cargos)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(c.abonos)}</td>
                           <td align="center">{"$" + new Intl.NumberFormat("en-US").format(saldoParcialTabla)}</td>
                           <td>
                           {user.facturas_abonos_edit && c.movimiento=="Abono" ? (
                               <Button size="sm" className="btn" color="danger" 
                               onClick={(e) => PDFRecibo(c.idAbonoFactura, c.fecha, props.nombre, props.apellido, c.abonos)}
                               >
                               <i class="far fa-file-pdf"></i>
                             </Button>):undefined    
                               }
                               </td>
                         </tr>
                        );
                      }})}
                      <tr>
                        <td></td>
                        <td></td>
                        <td td className="negrita" align="center">TOTALES</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargos)}</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalAbonos)}</td>
                        <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldo)}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
            </div>
        </>
    )
}

export default FacturasInfo
