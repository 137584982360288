import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosSalidaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaSalida, setAreaSalida] = useState("");
  const [areaDestino, setAreaDestino] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [articulos, setArticulos] = useState([]);

  const [coloresUnicos, setColoresUnicos] = useState([]);
  const [tallasUnicos, setTallasUnicos] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      idInv: "",
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      tc: [],
      totalArt: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTraspasosSalida = async (event) => {
    event.preventDefault();
    if(areaDestino !="" && areaSalida != ""){
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;
    try {
      await axios
        .post(
          URL_TRASPASOS_SALIDA,
          {
            fecha,
            areaSalida,
            areaDestino,
            observaciones,
            user: user.id,
            total,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if(a.articulos != ""){
              a.tc.map((b)=>{
                if(b.cantidad >0){
                axios
                .post(
                  URL_ARTICULOS_TS,
                  {
                    traspasosSalida: data.data._id,
                    articulos: a.articulos,
                    cantidad: parseFloat(b.cantidad),
                    inventarios: b.idInv,
                    colores: b.colores,
                    tallas: b.tallas
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Traspaso Salida",
                          detalle: `${areaSalida} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                  setValidaBoton(true);
                })
              }
              })
            }

          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }} else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Falta seleccionar el area!",
      });
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        idInv: "",
        colores: "",
        arrayColoresArticulo: [],
        tallas: "",
        arrayTallasArticulo: [],
        tc: [],
        totalArt: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, colores, tallas, idInv, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.tc.map((a) => {
          if (a.idInv == idInv) {
            if( a.cantInv >= event.target.value ){
            a.cantidad = event.target.value
          }else{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "La cantidad excede el inventario!",
            });
          }}
        });

        let can = i.tc.map((c) => parseFloat(c.cantidad));
        let TC = can.reduce((t, total, index) => t + total, 0);

        i.totalArt = TC;
      
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };


  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newInputFields2 = [];

    let coloresArray = Object.values(coloresArticulo);
    let tallasArray = Object.values(tallasArticulo);

    coloresArray.map((a) => {
      tallasArray.map((b) => {
        newInputFields2.push({
          colores: a._id,
          colorNombre: a.name,
          tallas: b._id,
          tallaNombre: b.name,
          cantidad: 0,
          cantInv: 0,
          idInv: ""
        });
      });
    });

   newInputFields2.map((a)=>{
      inventarios.map((b)=>{
        if(idArticulo == b.idArticulo && a.colores == b.idColor && a.tallas == b.idTalla ){
          a.cantInv = b.cantidad
          a.idInv = b._id
        }
        return a
      })
    })


    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        i.tc = newInputFields2;
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };




  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.totalArt));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function jalaAlmacenSalida(e) {
    setAreaSalida(e);
    toggleProgreso()

      axios
        .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allInventarios = res.data;
          setInventarios(allInventarios);

        })
        .catch((err) => {
          console.log(err);
        });
    
        setTimeout(()=>{
          setModalProgreso(false)
        }, 3000)

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        idInv: "",
        colores: "",
        arrayColoresArticulo: [],
        tallas: "",
        arrayTallasArticulo: [],
        tc: [],
        totalArt: 0,
      },
    ]);
  }


  function jalaAlmacenDestino(e) {
    setAreaDestino(e);
    toggleProgreso()

      setTimeout(()=>{
        setModalProgreso(false)
      }, 3000)

    setInputFields([
      {
        id: uuidv4(),
      articulos: "",
      cantidad: 0,
      idInv: "",
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      tc: [],
      totalArt: 0,
      },
    ]);
  }

 
console.log("hola", inputFields)

  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_salida_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Salida</h3>
          <Form onSubmit={saveTraspasosSalida}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Salida</Label>
                {user.areas == AREAS_GENERAL ? (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                ) : (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                )}
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    jalaAlmacenDestino(e.target.value);
                  }}
                >
                  <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.codigo}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="totalArt"
                      type="number"
                      placeholder="Piezas"
                      value={inputField.totalArt}
                      disabled
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>

                {inputField.arrayColoresArticulo.map((col) => {
                  return (
                    <>
                      <Row>
                        <Col md={9} className="tallas">
                          <Col md={1}>
                            <Label>{col.name}</Label>
                          </Col>
                          {inputField.tc.map((a) => {
                            if (a.colores == col._id) {
                              return (
                                <>
                                  <Col md={1}>
                                    <Label>{a.tallaNombre} / Inv. {a.cantInv}</Label>
                                    <Input
                                      bsSize="sm"
                                      name="cantidad"
                                      type="number"
                                      value={a.cantidad}
                                      required
                                      onChange={(event) =>
                                        handleChangeInputCantidad(
                                          inputField.id,
                                          a.colores,
                                          a.tallas,
                                          a.idInv,
                                          event
                                        )
                                      }
                                    />
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </>
                  );
                })}

              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosSalida"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosSalidaCreate;
