import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function ProveedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const [text, setText] = useState(false);
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("NA");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [contacto, setContacto] = useState("");
  const [telefono, setTelefono] = useState(0);
  const [email, setEmail] = useState("a@a.a");
  const [nacInt, setNacInt] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [plazo, setPlazo] = useState(0);
  const [banco, setBanco] = useState("NA");
  const [clabe, setClabe] = useState("NA");
  const [beneficiario, setBeneficiario] = useState("NA");

  const clear = () => {
    setNombreComercial("");
    setRazonSocial("");
    setRFC("");
    setCalle("");
    setNumeroExt("");
    setNumeroInt("");
    setDelegacion("");
    setEstado("");
    setPais("");
    setCP("");
    setColonia("");
    setCiudad("");
    setContacto("");
    setTelefono("");
    setEmail("");
    setNacInt("")
    setValidaBoton(true)
  };


  const saveProveedores = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_PROVEEDORES,
        {
          nombre_comercial,
          razon_social,
          RFC,
          calle,
          numero_ext,
          numero_int,
          delegacion,
          estado,
          pais,
          cp,
          colonia,
          ciudad,
          contacto,
          telefono,
          email,
          cargos: 0,
          abonos: 0,
          saldo: 0,
          tipo: "Proveedor",
          nacInt,
          plazo,
          banco,
          clabe,
          beneficiario
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Proveedor',
            detalle: `Nombre Comercial: ${nombre_comercial}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true)
      });
  };

  return (
    <>
     <Header />
    <br />
    <br />
    {user.proveedores_create  ?(
    <div className="container">
        <div className="card container">
          <h3 align="center">Nuevo Proveedor</h3>
          <Form onSubmit={saveProveedores}>

          <Row>
              <Col md={4}>
                  <Label>Nombre Comercial</Label>
                  <Input
                    type="text"
                    placeholder="Nombre Comercial"
                    value={nombre_comercial}
                    required
                    onChange={(e) => {
                      setNombreComercial(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Razon Social</Label>
                  <Input
                    type="text"
                    placeholder="Razon Social"
                    value={razon_social}
                    required
                    onChange={(e) => {
                      setRazonSocial(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>RFC</Label>
                  <Input
                    type="text"
                    placeholder="RFC"
                    value={RFC}
                    required
                    onChange={(e) => {
                      setRFC(e.target.value);
                    }}
                  />
              </Col>
            </Row>
          <Row>
              <Col md={4}>
                  <Label>Contacto</Label>
                  <Input
                    type="text"
                    placeholder="Contacto"
                    value={contacto}
                    required
                    onChange={(e) => {
                      setContacto(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Telefono</Label>
                  <Input
                    type="number"
                    placeholder="Telefono"
                    value={telefono}
                    required
                    onChange={(e) => {
                      setTelefono(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
              </Col>
            </Row>

            <Row>
            <Col md={4}>
                  <Label>Tipo</Label>
                  <Input
                    type="select"
                    placeholder="Tipo"
                    value={nacInt}
                    required
                    onChange={(e) => {
                      setNacInt(e.target.value);
                    }}
                    >
                    <option value="">Selecciona</option>
                    <option value="Nacional">Nacional</option>
                    <option value="Internacional">Internacional</option>
                  </Input>
              </Col>
              <Col md={4}>
                  <Label>Calle</Label>
                  <Input
                    type="text"
                    placeholder="Calle"
                    value={calle}
                    required
                    onChange={(e) => {
                      setCalle(e.target.value);
                    }}
                  />
              </Col>
              <Col md={2}>
                  <Label>No. Ext</Label>
                  <Input
                    type="text"
                    placeholder="No. Ext"
                    value={numero_ext}
                    required
                    onChange={(e) => {
                      setNumeroExt(e.target.value);
                    }}
                  />
              </Col>
              <Col md={2}>
                  <Label>No. Int</Label>
                  <Input
                    type="text"
                    placeholder="No. Int"
                    value={numero_int}
                    required
                    onChange={(e) => {
                      setNumeroInt(e.target.value);
                    }}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                  <Label>Pais</Label>
                  <Input
                    type="text"
                    placeholder="Pais"
                    value={pais}
                    required
                    onChange={(e) => {
                      setPais(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Estado</Label>
                  <Input
                    type="text"
                    placeholder="Estado"
                    value={estado}
                    required
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Ciudad</Label>
                  <Input
                    type="text"
                    placeholder="Ciudad"
                    value={ciudad}
                    required
                    onChange={(e) => {
                      setCiudad(e.target.value);
                    }}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                  <Label>Delegacion</Label>
                  <Input
                    type="text"
                    placeholder="Delegacion o Municipio"
                    value={delegacion}
                    required
                    onChange={(e) => {
                      setDelegacion(e.target.value);
                    }}
                  />
              </Col>
              <Col md={4}>
                  <Label>Colonia</Label>
                  <Input
                    type="text"
                    placeholder="Colonia"
                    value={colonia}
                    required
                    onChange={(e) => {
                      setColonia(e.target.value);
                    }}
                  />
              </Col>
              <Col md={2}>
                  <Label>CP</Label>
                  <Input
                    type="text"
                    placeholder="CP"
                    value={cp}
                    required
                    onChange={(e) => {
                      setCP(e.target.value);
                    }}
                  />
              </Col>
              <Col md={2}>
                  <Label>Plazo</Label>
                  <Input
                    type="number"
                    placeholder="Plazo"
                    value={plazo}
                    required
                    onChange={(e) => {
                      setPlazo(e.target.value);
                    }}
                    />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
            <Button
              href="/Proveedores"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      </div>
      ): undefined } 
      
    </>
  );
}

export default ProveedoresCreate;
