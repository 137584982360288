import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";


function ListadoSurtidos() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;


  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_SURTIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              codigoClientes: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              impuestos: a.pedidos[0].impuestos,
              subTotal: a.pedidos[0].subTotal,
              iva: a.pedidos[0].iva,
              total_general: a.pedidos[0].total_general,
              idDirEnt: a.pedidos[0].sucursales[0]._id,
              alias: a.pedidos[0].sucursales[0].alias,
              calle: a.pedidos[0].sucursales[0].calle,
              numero_ext: a.pedidos[0].sucursales[0].numero_ext,
              numero_int: a.pedidos[0].sucursales[0].numero_int,
              pais: a.pedidos[0].sucursales[0].pais,
              poblacion: a.pedidos[0].sucursales[0].poblacion,
              estado: a.pedidos[0].sucursales[0].estado,
              ciudad: a.pedidos[0].sucursales[0].ciudad,
              delegacion: a.pedidos[0].sucursales[0].delegacion,
              colonia: a.pedidos[0].sucursales[0].colonia,
              cp: a.pedidos[0].sucursales[0].cp,
              area: a.areas[0].name,
              vendedor: a.pedidos[0].colaboradores[0].nombre + " "+ a.pedidos[0].colaboradores[0].apellido
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );


  function PDFTabla() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 23, 48, 14);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero","Fecha", "Cliente", "Pedido", "Surtido"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    // doc.save(`Surtidos.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Surtio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["Numero","Fecha", "Cliente", "Pedido", "Surtio"],
        sheetHeader: ["Numero","Fecha", "Cliente", "Pedido", "Surtio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 23, 48, 14);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero","Fecha", "Cliente", "Pedido", "Surtido"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  async function PDFOC(
    fecha,
    clientes,
    numero,
    idPDFOC,
    user,
    pedidos,
    impuestos,
    subTotal,
    iva,
    total_general,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    poblacion,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    area,
    codigoClientes,
    observaciones
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              precio: a.articulosPedido[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        // let t = arrayTabla.length;
        // let arrayPrecio = arrayTabla.map((a) => {
        //   axios
        //     .get(
        //       `${URL_ARTICULOS_PEDIDO}/precio/${idPDFOC}/${a.idArticulo}`,
        //       {
        //         headers: {
        //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //         },
        //       }
        //     )
        //     .then((resp) => {
        //       t = t - 1;
        //       let precio = resp.data[0].precio;
        //       let idArticulo = resp.data[0].articulos[0]._id;

        //       let arrayP = arrayTabla.map((i) => {
        //         if (i.idArticulo == idArticulo) {
        //           i.precio = precio;
        //         }
        //         return i;
        //       });

        //       if (t == 0) {
                let dataFinal = Object.values(arrayTabla);

                let agrupado = dataFinal.reduce(function (groups, item) {
                  const val = item["idArticulo"];
                  groups[val] = groups[val] || {
                    idArticulo: item.idArticulo,
                    cantidad: 0,
                  };
                  groups[val].cantidad += item.cantidad;
                  groups[val].articulos = item.articulos;
                  groups[val].precio = item.precio;
                  return groups;
                }, []);

                let agrupadoValues = Object.values(agrupado);


                let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
                let TM = totalMts.reduce((t, total, index) => t + total, 0);
               
                let totalParcial = 0;
                let subTotalTabla = 0
                let total_general_tabla = 0
                let iva_tabla = 0 
                let impuesto_tabla = impuestos


                const data = agrupadoValues.map((a) => {
                  totalParcial = a.cantidad * a.precio;
                  subTotalTabla = subTotalTabla + totalParcial
                  iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
                  return [
                    a.articulos,
                    new Intl.NumberFormat("en-US").format(a.cantidad),
                    "$" + new Intl.NumberFormat("en-US").format(a.precio),
                    "$" + new Intl.NumberFormat("en-US").format(totalParcial),
                  ];
                });

                const dataPDF = Object.values(data);
                const dataPDFLimpia = dataPDF.filter(function (el) {
                  return el != null;
                });
                total_general_tabla = subTotalTabla + iva_tabla
                const doc = new jsPDF("landscape");
                var base64 = qrcode.toDataURL(idPDFOC);
                var img3 = new Image();
                img3.src = base64;
                doc.addImage(img3, "png", 255, 30, 25, 25);
                var img = new Image();
                img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                doc.addImage(img, "png", 255, 10, 25, 8);
                var img2 = new Image();
                img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                doc.addImage(img2, "png", 230, 18, 48, 14);
                doc.text(`Nota # ${numero}`, 20, 20);
                doc.setFontSize(12);
                doc.text(`Almacen: ${area}`, 20, 25);
                doc.text(`Fecha: ${fecha}`, 20, 30);
                doc.text(`Cliente: ${codigoClientes} ${clientes}`, 20, 35);
                doc.text(`Pedido # ${pedidos}`, 20, 40);
                doc.text(`Observaciones ${observaciones}`, 20, 45);
                doc.text(`Sucursal: ${alias}`, 100, 20);
                doc.text(`Edo: ${estado} Ciudad: ${ciudad} Pob: ${poblacion}`, 100, 25);
                doc.text(`Dir: ${calle} ${numero_ext} ${numero_int}`, 100, 30);
                doc.text(`Del: ${delegacion} Col: ${colonia} CP: ${cp}`, 100, 35);
                doc.autoTable({
                  head: [
                    [
                      "Articulo",
                      "Cantidad",
                      "Precio",
                      "Total",
                    ],
                  ],
                  body: dataPDFLimpia,
                  startY: 60,
                  foot: [
                    [
                      "",
                      new Intl.NumberFormat("en-US").format(TM) + " Piezas",
                      "Sub Total",
                      "$" + new Intl.NumberFormat("en-US").format(subTotal),
                    ],
                    [
                      "",
                      "",
                      `IVA ${impuestos}%`,
                      "$" + new Intl.NumberFormat("en-US").format(iva),
                    ],
                    [
                      "",
                      "",
                      "Total",
                      "$" +new Intl.NumberFormat("en-US").format(total_general),
                    ],
                  ],
                  showFoot: "lastPage",
                });

                // doc.save(`Nota-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
            //   }
            // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }


 

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Surtido", field: "user", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase())||
          comment.area.toLowerCase().includes(search.toLowerCase())||
          comment.vendedor.toLowerCase().includes(search.toLowerCase())||
          comment.pedidos.toString().includes(search)||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "pedidos" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "pedidos" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "pedidos" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
                
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Notas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.area}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.pedidos}</td>
                      <td>{a.user}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(
                              a.fecha,
                              a.clientes,
                              a.numero,
                              a._id,
                              a.user,
                              a.pedidos,
                              a.impuestos,
                              a.subTotal,
                              a.iva,
                              a.total_general,
                              a.alias,
                              a.calle,
                              a.numero_ext,
                              a.numero_int,
                              a.pais,
                              a.poblacion,
                              a.estado,
                              a.ciudad,
                              a.delegacion,
                              a.colonia,
                              a.cp,
                              a.area,
                              a.codigoClientes,
                              a.observaciones
                            )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoSurtidos;
